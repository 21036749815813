import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// TODO: Replace the following with your app's Firebase project configuration
// See: https://support.google.com/firebase/answer/7015592
const firebaseConfig = {
  apiKey: "AIzaSyCOdeXSYXRrodnEBMYCOM6ssPP2y2Ug9iM",
  authDomain: "farm-dapp.firebaseapp.com",
  projectId: "farm-dapp",
  storageBucket: "farm-dapp.appspot.com",
  messagingSenderId: "63927979060",
  appId: "1:63927979060:web:861b62872a905018b50e61",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);
