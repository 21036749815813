import axios from "axios";
import { formatEther, formatUnits, parseEther } from "ethers/lib/utils";
import React, { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import {
  AiFillExclamationCircle,
  AiOutlineChrome,
  AiOutlineFileDone
} from "react-icons/ai";
import { IoIosCopy } from "react-icons/io";
import { toast } from "react-toastify";
import { useAccount, useBalance, useContractWrite, useNetwork, useWaitForTransaction } from "wagmi";
import Web3 from "web3";
import logo from "../../assets/images/global/logo.svg";
import {
  IERC20,
  chainName,
  coinGeckoTicker,
  presaleAbi,
  rpc,
  scanLinks,
} from "../../config";
import { factoryAbi } from "../../constants/factoryAbi";
import { routerAbi } from "../../constants/routerAbi";
import ResponsiveDialog from "../../spinner";
import { notifyError, notifySuccess } from "../../utils/toastError";
import Button from "./Button";
import moment from "moment";


const NewCard = ({ conAdd, referrer, chainId }) => {
  const [showBuy, setshowBuy] = useState(false);
  const [Samount, setSAmount] = useState(0);
  const [showAlert, setshowAlert] = useState(false);
  const { address, isConnected } = useAccount()
  const { chain } = useNetwork()
  const { data: dataNativeBalance } = useBalance({
    address: address,
    chainId: chainId,
  });


  const [bal, setBal] = useState(0);
  const web3 = new Web3(new Web3.providers.HttpProvider(rpc[chainId]));
  const contractR = new web3.eth.Contract(presaleAbi, conAdd);

  const [bonus, setSetBonus] = useState(false);
  const [amount, setAmount] = useState('');
  const [data, setData] = useState();
  const [dollarPrice, setDollarPrice] = useState(0);
  const [userBalance, setUserBalance] = useState(0);
  const [userBNBBalance, setUserBNBBalance] = useState(0);
  const [userLPBalance, setUserLPBalance] = useState(0);
  const [userPaid, setUserPaid] = useState(0);
  const [userPending, setUserPending] = useState(0);
  const [openSpinner, setOpenspinner] = useState(false);
  const [APR, setAPR] = useState(0);
  const [admin, setAdmin] = useState("");
  const [cancelled, setCancelled] = useState(false);
  const [userStakedbalance, setUserStakedBalance] = useState(0);
  const [referralBonus, setReferralBonus] = useState(0);
  const [userReferralIncome, setUserReferralIncome] = useState(0);
  const [emergencyTax, setEmergencyTax] = useState(0);
  const [contractBalance, setContractBalance] = useState(0);
  const [totalStaked, setTotalStaked] = useState(0);
  const [spStatus, setSPStatus] = useState("");
  const [finalized, setFinalized] = useState(false);
  const [perTokenPriceBnb, setPerTokenPriceBnb] = useState(0);

  const [emergencyUnstakeAmount, setEmergencyUnstakeAmount] = useState("0");
  const [lpValueInBnb, setLpValueInBnb] = useState(0);
  const [totalTokensLocked, setTotalTokensLocked] = useState(0);
  const [totalLpLocked, setTotalLpLocked] = useState(0);
  const [poolStartTime, setPoolStartTime] = useState(0);
  const [poolEndTime, setPoolEndTime] = useState(0);


  // console.log(finalized,"finalized",conAdd)

  useEffect(() => {
    init();
  }, [isConnected, address]);

  const init = async () => {
    const _data = await contractR.methods.getSelfInfo().call();

    setData(_data);

    const _poolStartTime = await contractR.methods.poolStartTime().call();
    setPoolStartTime(_poolStartTime);
    const _poolEndTime = await contractR.methods.poolEndTime().call();
    setPoolEndTime(_poolEndTime);

    // console.log("router", _data[18])
    const TokencontractR = new web3.eth.Contract(IERC20, _data[3]);

    const routerR = new web3.eth.Contract(routerAbi, _data[18]);
    const factoryAddress = await routerR.methods.factory().call();
    const WethAddress = await routerR.methods.WETH().call();
    const wethContract = new web3.eth.Contract(IERC20, WethAddress);

    // console.log("factoryAddress", factoryAddress, WethAddress)
    const factoryR = new web3.eth.Contract(factoryAbi, factoryAddress);
    const pairAddress = await factoryR.methods.getPair(_data[3], WethAddress).call();
    const pairContract = new web3.eth.Contract(IERC20, pairAddress);





    const reserve0 = await TokencontractR.methods.balanceOf(pairAddress).call();
    const reserve1 = await wethContract.methods.balanceOf(pairAddress).call();

    const totalSupply = await pairContract.methods.totalSupply().call();

    setLpValueInBnb(+reserve1 / +totalSupply)

    // const _totalTokensLocked =  await contractR.methods.owner().call();
    // const _totalLpLocked = await contractR.methods.owner().call();


    const tokenDecimals = await TokencontractR.methods.decimals().call();

    const pariTokenBlance = +formatUnits(
      reserve0.toString(),
      tokenDecimals.toString()
    );
    const pairBnbBlance = +formatEther(reserve1.toString());

    const price = pairBnbBlance / pariTokenBlance;


    // console.log("perTokenPriceBNB", perTokenPrice)
    setPerTokenPriceBnb(price);



    const _own = await contractR.methods.owner().call();

    setAdmin(_own);

    const _can = await contractR.methods.poolCancelled().call();

    setCancelled(_can);

    const _apr = await contractR.methods.rewardRate().call();

    setAPR(_apr);

    const _ET = await contractR.methods.emergencyFee().call();

    setEmergencyTax(_ET);

    const _fin = await contractR.methods.poolFinalized().call();

    // console.log(_fin,"_fin",conAdd)

    setFinalized(_fin);

    try {
      const ethPrice = await axios.get(
        `https://api.coingecko.com/api/v3/simple/price?ids=${coinGeckoTicker[chainId]}&vs_currencies=usd`
      );

      // console.log("ethPrice.data[coinGeckoTicker[chain]].usd", ethPrice.data[coinGeckoTicker[chain]].usd)

      setDollarPrice(ethPrice.data[coinGeckoTicker[chainId]].usd);
    } catch (error) {
      console.log("error", error)
    }

    const _RB = await contractR.methods.refferralPercentage().call();

    setReferralBonus(_RB);

    const _TS = await contractR.methods._totalStaked().call();

    setTotalStaked(_TS);

    const _CB = await TokencontractR.methods.balanceOf(conAdd).call();

    setContractBalance(_CB);

    if (address) {
      const _uBal = await contractR.methods
        .userPresaleTBalance(address)
        .call();
      setUserBalance(_uBal);

      const _uBBal = await contractR.methods
        .userPresaleMBalance(address)
        .call();
      setUserBNBBalance(_uBBal);

      const _uLBal = await contractR.methods
        .userPresaleLBalance(address)
        .call();
      setUserLPBalance(_uLBal);

      const _uearned = await contractR.methods.earned(address).call();
      setUserPending(_uearned);

      const _upaid = await contractR.methods
        .userPaidRewards(address)
        .call();
      setUserPaid(_upaid);

      const _preStake = await contractR.methods
        .userStakedBalance(address)
        .call();
      setUserStakedBalance(_preStake);

      const _refIncome = await contractR.methods
        .ReferralIncome(address)
        .call();
      setUserReferralIncome(_refIncome);

      const _bal = await web3.eth.getBalance(address);
      setBal(formatEther(_bal));
    }
  };

  const now = new Date().getTime() / 1000;
  const status =
    data && finalized ? "COMPLETED" : cancelled ? "CANCELLED" : "LIVE";

  const progress = data
    ? (Number(formatEther(data[5])) / Number(formatEther(data[0]))) * 100
    : 0;

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  function convertToDate(str) {
    const _day = new Date(str * 1000).getDate();
    const formatedDays = _day / 10 > 1 ? `${_day}` : `0${_day}`;
    const _month = new Date(str * 1000).getMonth();
    const formatedMonths = _month / 10 > 1 ? `${_month}` : `0${_month}`;
    const _year = new Date(str * 1000).getFullYear();
    const _hours = new Date(str * 1000).getHours();
    const formatedHours = _hours / 10 > 1 ? `${_hours}` : `0${_hours}`;
    const _minutes = new Date(str * 1000).getMinutes();
    const formatedMinutes = _minutes / 10 > 1 ? `${_minutes}` : `0${_minutes}`;

    return `${months[_month]} ${formatedDays},${_year}  ${formatedHours}:${formatedMinutes}`;
  }

  const EndDate = data && data[7] * 1000 + data[14] * (60 * 60 * 24 * 1000);
  const nowdate = new Date().getTime();

  const _days = Math.abs(+poolStartTime - +poolEndTime) / 60 / 60 / 24;
  const _remaingDays =
    Math.abs(+poolStartTime - +moment().unix()) / 60 / 60 / 24;

  const remainingDays = (_days > _remaingDays) ? parseInt(_days - _remaingDays) : 0;


  // const remainingDays =
  //   data && EndDate - nowdate >= 0
  //     ? ((EndDate - nowdate) / (60 * 60 * 24 * 1000)).toFixed(0)
  //     : 0;

  const youtubeArray = data && data[16].split("watch?v=");
  const youtubeVideo =
    data && `https://www.youtube.com/embed/${youtubeArray[1]}`;

  const socials = data && data[20]?.split("=");
  const website = data && socials[0];
  const telegram = data && socials[1];
  const discord = data && socials[2];

  const { write: buyTokens, data: buyData } = useContractWrite({
    address: conAdd,
    abi: presaleAbi,
    functionName: 'purchaseSafoBill',
    args: [referrer ? referrer : "0x0000000000000000000000000000000000000000"],
    value: amount.length > 0 ? parseEther(amount.toString()).toString() : "0",
    onError: (error) => {
      var str = JSON.stringify(error)
      var obj = JSON.parse(str)
      var reason = obj.cause.reason
      reason ? notifyError(`${reason}`) : notifyError(`${obj?.details}`)
    }
  })

  const buyTx = useWaitForTransaction({
    hash: buyData?.hash,
    onSuccess: () => {
      notifySuccess('Token Bought Successfully')
      init();
    }
  })

  const { write: rewards, data: rewardsData } = useContractWrite({
    address: conAdd,
    abi: presaleAbi,
    functionName: 'claimRewards',
    onError: (error) => {
      var str = JSON.stringify(error)
      var obj = JSON.parse(str)
      var reason = obj.cause.reason
      reason ? notifyError(`${reason}`) : notifyError(`${obj?.details}`)
    }
  })

  const rewardsTx = useWaitForTransaction({
    hash: rewardsData?.hash,
    onSuccess: () => {
      notifySuccess('Rewards Claimed Successfully')
      init();
    }
  })

  const { write: emergencyUnstake, data: emergencyData } = useContractWrite({
    address: conAdd,
    abi: presaleAbi,
    functionName: 'emergencySale',
    args: [emergencyUnstakeAmount.length > 0 ? String(+emergencyUnstakeAmount * 100) : "0"],
    onError: (error) => {
      var str = JSON.stringify(error)
      var obj = JSON.parse(str)
      var reason = obj.cause.reason
      reason ? notifyError(`${reason}`) : notifyError(`${obj?.details}`)
    }
  })

  const emergencyTx = useWaitForTransaction({
    hash: emergencyData?.hash,
    onSuccess: () => {
      notifySuccess('Tokens Emergency Unstake Successfully')
      init();
    }

  })

  const { write: unStake, data: unstakeData } = useContractWrite({
    address: conAdd,
    abi: presaleAbi,
    functionName: 'redeemSafoBill',
    args: [emergencyUnstakeAmount.length > 0 ? String(+emergencyUnstakeAmount * 100) : "0"],
    onError: (error) => {
      var str = JSON.stringify(error)
      var obj = JSON.parse(str)
      var reason = obj.cause.reason
      reason ? notifyError(`${reason}`) : notifyError(`${obj?.details}`)
    }
  })

  const unstakeTx = useWaitForTransaction({
    hash: unstakeData?.hash,
    onSuccess: () => {
      notifySuccess('Tokens Sell Successfully')
      init();
    }

  })

  const { write: refund, data: refundData } = useContractWrite({
    address: conAdd,
    abi: presaleAbi,
    functionName: 'refund',
    args: [emergencyUnstakeAmount.length > 0 ? String(+emergencyUnstakeAmount * 100) : "0"],
    onError: (error) => {
      var str = JSON.stringify(error)
      var obj = JSON.parse(str)
      var reason = obj.cause.reason
      reason ? notifyError(`${reason}`) : notifyError(`${obj?.details}`)
    }
  })

  const refundTx = useWaitForTransaction({
    hash: refundData?.hash,
    onSuccess: () => {
      notifySuccess('Tokens Refund Successfully')
      init();
    }

  })

  const { write: cancel, data: cancelData } = useContractWrite({
    address: conAdd,
    abi: presaleAbi,
    functionName: 'cancel',
    onError: (error) => {
      var str = JSON.stringify(error)
      var obj = JSON.parse(str)
      var reason = obj.cause.reason
      reason ? notifyError(`${reason}`) : notifyError(`${obj?.details}`)
    }
  })

  const cancelTx = useWaitForTransaction({
    hash: cancelData?.hash,
    onSuccess: () => {
      notifySuccess('Sale Cancelled Successfully')
      init();
    }

  })


  const handleBuy = () => {
    setshowBuy(!showBuy);
  };

  const handleAlert = () => {
    setshowAlert(!showAlert);
  };

  const Row = ({ text, value, price }) => (
    <div className="flex justify-between items-center">
      <p className="text-lyellow text-xl font-semibold w-[50%]">{text}:</p>
      <div className="bg-lightgreen px-8  py-1 rounded-xl flex flex-wrap w-56 justify-between items-center">
        <p className="text-black font-semibold text-base text-center">
          {value}
        </p>
        <p className="text-black text-base text-center">-${price}</p>
      </div>
    </div>
  );

  // console.log("bounties", status,conAdd)
  const tokenSymbol = {
    56: "BNB",
    97: "BNB",
    1: "ETH"
  }
  return (
    status == "COMPLETED" && (
      <>
        <div className="w-full flex flex-col justify-between">
          <div className="flex justify-evenly flex-col md:flex-row mt-8">
            <div className="">
              <div className="space-y-4 text-white">
                <div className="flex flex-col items-center justify-between px-[5em] pb-5 pt-2">
                  <div className="relative block px-6 py-4 bg-lyellow rounded-t-2xl border border-black/20 shadow-md w-[22em] sm:w-[30em] xl:w-[35em] ">
                    <div className="flex items-center">
                      <div className="flex flex-wrap justify-between items-center w-[100%]">
                        <div>
                          <p className="text-white text-xl font-semibold">
                            Earn {data && data[15]}
                          </p>
                          <p className="text-white text-sm italic">
                            stake {data && data[15]}-Bills
                          </p>
                        </div>
                        <div className="mt-2 sm:none">
                          <p className="text-white text-sm">
                            Start:{" "}
                            {Number(poolStartTime) > 0
                              ? convertToDate(Number(poolStartTime))
                              : "00:00:00  00:00"
                            }

                          </p>
                          <p className="text-white text-sm">
                            End:{" "}
                            {
                              Number(poolEndTime) > 0
                                ? convertToDate(
                                  Number(poolEndTime)
                                )
                                : "00:00:00  00:00"
                            }
                          </p>
                          <p className="text-white text-sm">
                            Duration: {data && data[14]} days
                          </p>
                          <p className="text-white text-sm">
                            Remaining: {remainingDays} days
                          </p>
                        </div>
                        <img
                          className="w-16 h-16 rounded-full hidden sm:block "
                          src={data && data[12]}
                          alt={data && data[15]}
                        />
                      </div>
                      <img
                        className="w-16 h-16 rounded-full  sm:hidden"
                        src={data && data[12]}
                        alt={data && data[15]}
                      />
                    </div>
                    <div className="absolute right-0 top-0 bg-lightgreen py-[2px] px-2 rounded-sm">
                      <p className="text-white text-sm">New!</p>
                    </div>
                  </div>

                  <div className="block px-6 py-3 bg-lgreen w-[22em] sm:w-[30em] xl:w-[35em] ">
                    <div className="flex items-center justify-center">
                      <div className=" text-white">
                        <a
                          className="flex items-center justify-center"
                          href={website}
                          target="_blank"
                        >
                          <p className="text-white text-sm mr-[4px]">
                            {data && data[15]}
                          </p>
                          <AiOutlineChrome size={15} />
                        </a>
                      </div>
                      <p className="mx-2 text-sm text-white">/</p>
                      <div className=" text-white">
                        <a
                          className="flex items-center justify-center"
                          href={data && `${scanLinks[chainId]}/address/${conAdd}`}
                          target="_blank"
                        >
                          <p className="text-white text-sm mr-[4px]">
                            {data && data[15]}-Bills
                          </p>
                          <AiOutlineFileDone size={15} />
                        </a>
                      </div>
                      <p className="mx-2 text-sm text-white">/</p>
                      <div className=" text-white">
                        <a
                          className="flex items-center justify-center"
                          href={data && `${scanLinks[chainId]}/address/${data[3]}`}
                          target="_blank"
                        >
                          <p className="text-white text-sm mr-[4px]">
                            {data && data[15]}
                          </p>
                          <AiOutlineFileDone size={15} />
                        </a>
                      </div>
                    </div>
                    <div className="flex flex-col justify-center space-y-5 mt-6">
                      <Row
                        text={`${tokenSymbol[chainId]} Balance`}
                        value={dataNativeBalance ? Number(dataNativeBalance.formatted).toFixed(3) : "0"}
                        price={dataNativeBalance ? (dollarPrice * Number(dataNativeBalance.formatted)).toFixed(2) : "0"}
                      />
                      <Row
                        text={`${data && data[15]} Balance`}
                        value={Number(formatEther(userBalance)).toFixed(3)}
                        price={
                          data &&
                          (
                            Number(formatEther(userBalance)) *
                            dollarPrice *
                            perTokenPriceBnb
                            // Number(formatEther(data[2]))
                          ).toFixed(2)
                        }
                      />
                      <Row
                        text={`${data && data[15]}-Bills Staked`}
                        value={Number(formatEther(userLPBalance)).toFixed(3)}
                        price={
                          data &&
                          (
                            Number(formatEther(userLPBalance)) *
                            dollarPrice *
                            lpValueInBnb
                          ).toFixed(2)
                        }
                      />
                      <Row
                        text={"Pending Rewards"}
                        value={Number(formatEther(userPending)).toFixed(3)}
                        price={
                          data &&
                          Number(
                            Number(formatEther(userPending)) *
                            // Number(formatEther(data[2])) *
                            perTokenPriceBnb *
                            dollarPrice
                          ).toFixed(2)
                        }
                      />

                      <Row
                        text={"Paid Rewards"}
                        value={Number(formatEther(userPaid)).toFixed(3)}
                        price={
                          data &&
                          Number(
                            Number(formatEther(userPaid)) *
                            perTokenPriceBnb *
                            // Number(formatEther(data[2])) *
                            dollarPrice
                          ).toFixed(2)
                        }
                      />
                    </div>

                    {!cancelled && (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <p>ENTER PURCHASE AMOUNT</p>
                        <span style={{ display: "flex" }}>
                          <input
                            type="number"
                            style={{ color: "black" }}
                            value={amount}
                            onChange={(e) => {
                              setAmount(e.target.value);
                            }}
                          />
                          <div className="flex flex-col justify-center items-center">
                            <span
                              onClick={async () => {
                                if (
                                  data &&
                                  Number(bal) < Number(formatEther(data[9]))
                                ) {
                                  setAmount(bal);
                                } else {
                                  setAmount(formatEther(data[9]));
                                }
                              }}
                              style={{ cursor: "pointer" }}
                              className="bg-[#172c73] rounded py-1 px-4 text-sm"
                            >
                              Max
                            </span>
                          </div>
                        </span>
                      </div>
                    )}

                    <div className="h-[2px] bg-white w-full mt-5" />

                    {data && userStakedbalance > 0 && (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <p>ENTER PERCENTAGE</p>
                        <span style={{ display: "flex" }}>
                          <input
                            type="number"
                            style={{ color: "black" }}
                            value={emergencyUnstakeAmount}
                            onChange={(e) => {
                              setEmergencyUnstakeAmount(e.target.value);
                            }}
                          />
                          <div className="flex flex-col justify-center items-center">
                            <span
                              onClick={async () => {
                                setEmergencyUnstakeAmount("100");
                              }}
                              style={{ cursor: "pointer" }}
                              className="bg-[#172c73] rounded py-1 px-4 text-sm"
                            >
                              Max
                            </span>
                          </div>
                        </span>
                      </div>
                    )}

                    {data && userStakedbalance > 0 && (
                      <div className="my-4 flex justify-center items-center font-sans pt-2 pb-4 ">
                        <div className="flex flex-col justify-center items-center">
                          <span
                            onClick={async () => {
                              setEmergencyUnstakeAmount("25");
                            }}
                            style={{ cursor: "pointer" }}
                            className="bg-[#172c73] rounded py-1 px-4 text-sm font-semibold"
                          >
                            25%
                          </span>
                        </div>

                        <div className="flex flex-col justify-center items-center ml-3">
                          <span
                            onClick={async () => {
                              setEmergencyUnstakeAmount("50");
                            }}
                            style={{ cursor: "pointer" }}
                            className="bg-[#172c73] rounded py-1 px-4 text-sm font-semibold"
                          >
                            50%
                          </span>
                        </div>

                        <div className="flex flex-col justify-center items-center ml-3">
                          <span
                            onClick={async () => {
                              setEmergencyUnstakeAmount("75")
                            }}
                            style={{ cursor: "pointer" }}
                            className="bg-[#172c73] rounded py-1 px-4 text-sm font-semibold"
                          >
                            75%
                          </span>
                        </div>

                        <div className="flex flex-col justify-center items-center ml-3">
                          <span
                            onClick={async () => {
                              setEmergencyUnstakeAmount("100");
                            }}
                            style={{ cursor: "pointer" }}
                            className="bg-[#172c73] rounded py-1 px-4 text-sm font-semibold"
                          >
                            100%
                          </span>
                        </div>
                      </div>
                    )}

                    {data && userStakedbalance > 0 && (
                      <div className="h-[2px] bg-white w-full mt-5" />
                    )}

                    <div className="my-4 flex justify-center items-center font-sans pt-2 pb-4 ">
                      {!cancelled && (
                        <div className="flex flex-col justify-center items-center">
                          <span
                            onClick={async () => {
                              const ref = referrer
                                ? referrer
                                : "0x0000000000000000000000000000000000000000";
                              // console.log("referral", ref);
                              if (!isConnected) {
                                notifyError("Please connect wallet")
                                return
                              }
                              if (chain?.id !== chainId) {
                                notifyError(`Please connect to ${chainName[chainId]}`)
                                return
                              }
                              buyTokens()

                              // writeFunction(
                              //   "Buy ",

                              //   "purchaseSafoBill",
                              //   presaleAbi,
                              //   conAdd,
                              //   500000,
                              //   [ref],
                              //   parseEther(amount.toString()),
                              //   () => {
                              //     toast.success("Buy function successful");
                              //   }
                              // );
                            }}
                            style={{ cursor: "pointer" }}
                            className="bg-[#172c73] rounded py-1 px-4 text-sm font-semibold"
                          >
                            Buy
                          </span>
                        </div>
                      )}

                      {data &&
                        userStakedbalance &&
                        Number(userStakedbalance) > 0 &&
                        cancelled ? (
                        <div className="flex flex-col justify-center items-center mx-4 ml-3 mr-0">
                          <span
                            onClick={async () => {
                              if (!isConnected) {
                                notifyError("Please connect wallet")
                                return
                              }
                              if (chain?.id !== chainId) {
                                notifyError(`Please connect to ${chainName[chainId]}`)
                                return
                              }
                              if (+emergencyUnstakeAmount > 100) {
                                notifyError("Please enter valid percentage")
                              } else {
                                refund()
                              }
                              // writeFunction(
                              //   "Refund ",

                              //   "refund",
                              //   presaleAbi,
                              //   conAdd,
                              //   500000,
                              //   [parseEther(Samount.toString())],
                              //   0,
                              //   () => {
                              //     toast.success("Refund function successful");
                              //   }
                              // );
                            }}
                            style={{ cursor: "pointer" }}
                            className="bg-[#172c73] rounded py-1 px-4 text-sm font-semibold"
                          >
                            Refund
                          </span>
                        </div>
                      ) : finalized && remainingDays <= 0 ? (
                        <div className="flex flex-col justify-center items-center mx-4 ml-3">
                          <span
                            onClick={async () => {
                              if (!isConnected) {
                                notifyError("Please connect wallet")
                                return
                              }
                              if (chain?.id !== chainId) {
                                notifyError(`Please connect to ${chainName[chainId]}`)
                                return
                              }
                              if (+emergencyUnstakeAmount > 100) {
                                notifyError("Please enter valid percentage")
                              } else {
                                unStake()
                              }
                              // writeFunction(
                              //   "Sell ",

                              //   "redeemSafoBill",
                              //   presaleAbi,
                              //   conAdd,
                              //   500000,
                              //   [parseEther(Samount.toString())],
                              //   0,
                              //   () => {
                              //     toast.success("Sell function successful");
                              //   }
                              // );
                            }}
                            style={{ cursor: "pointer" }}
                            className="bg-[#172c73] rounded py-1 px-4 text-sm font-semibold"
                          >
                            Sell
                          </span>
                        </div>
                      ) : Number(userStakedbalance) > 0 ? (
                        <div className="flex flex-col justify-center items-center mx-4 ml-3 mr-0">
                          <span
                            onClick={
                              () => {
                                if (!isConnected) {
                                  notifyError("Please connect wallet")
                                  return
                                }
                                if (chain?.id !== chainId) {
                                  notifyError(`Please connect to ${chainName[chainId]}`)
                                  return
                                }
                                if (+emergencyUnstakeAmount > 100) {
                                  notifyError("Please enter valid percentage")
                                } else {
                                  emergencyUnstake()
                                }
                              }

                              //   async () => {
                              //   writeFunction(
                              //     "Emergency Sell ",

                              //     "emergencySale",
                              //     presaleAbi,
                              //     conAdd,
                              //     500000,
                              //     [parseEther(Samount.toString())],
                              //     0,
                              //     () => {
                              //       toast.success("Emergency Sell successful");
                              //     }
                              //   );
                              // }
                            }
                            style={{ cursor: "pointer" }}
                            className="bg-[#172c73] rounded py-1 px-4 text-sm font-semibold"
                          >
                            {" "}
                            Emergency Sell
                          </span>
                        </div>
                      ) : null}

                      <div className="flex flex-col justify-center items-center ml-3">
                        <span
                          onClick={async () => {
                            if (!isConnected) {
                              notifyError("Please connect wallet")
                              return
                            }
                            if (chain?.id !== chainId) {
                              notifyError(`Please connect to ${chainName[chainId]}`)
                              return
                            }
                            rewards();
                            // writeFunction(
                            //   "harvest ",

                            //   "claimRewards",
                            //   presaleAbi,
                            //   conAdd,
                            //   500000,
                            //   [],
                            //   0,
                            //   () => {
                            //     toast.success("Harvest function successful");
                            //   }
                            // );
                          }}
                          style={{ cursor: "pointer" }}
                          className="bg-[#172c73] rounded py-1 px-4 text-sm font-semibold"
                        >
                          Harvest
                        </span>
                      </div>

                      {address &&
                        admin &&
                        address?.toLowerCase() == admin?.toLowerCase() ? (
                        <div className="flex flex-col justify-center items-center ml-3">
                          <span
                            onClick={async () => {
                              if (!isConnected) {
                                notifyError("Please connect wallet")
                                return
                              }
                              if (chain?.id !== chainId) {
                                notifyError(`Please connect to ${chainName[chainId]}`)
                                return
                              }
                              cancel()
                              // writeFunction(
                              //   "Cancel ",

                              //   "cancel",
                              //   presaleAbi,
                              //   conAdd,
                              //   500000,
                              //   [],
                              //   0,
                              //   () => {
                              //     toast.success("Harvest function successful");
                              //   }
                              // );
                            }}
                            style={{ cursor: "pointer" }}
                            className="bg-[#172c73] rounded py-1 px-4 text-sm font-semibold"
                          >
                            Cancel
                          </span>
                        </div>
                      ) : null}
                    </div>

                    {showAlert && (
                      <div className="space-y-4 pt-4">
                        <div className="bg-[#fffbe7] border rounded flex items-center p-1">
                          <AiFillExclamationCircle
                            size={35}
                            className="mr-1 text-[#f2b233] text-xl"
                          />
                          <p className="text-black font-semibold text-center text-xs">
                            Your staked tokens are locked until:{" "}
                            {data && Number(data[7]) > 0
                              ? convertToDate(
                                Number(data[7]) +
                                Number(data[14]) * (60 * 60 * 24)
                              )
                              : "00:00:00  00:00"}
                            . If you unstake before this date, you will be
                            penalized {emergencyTax}% of your staked tokens.
                          </p>
                        </div>
                        <div className="flex flex-wrap-reverse items-center justify-center sm:justify-between">
                          <Button text={"Cancel"} onClick={handleAlert} />
                          <button
                            className={`text-sm font-semibold mb-3 sm:mb-0 py-2 px-4 rounded-lg border-dashed border-red-600 border-2 bg-red-300 text-red-600 `}
                            onClick={async () => {
                              if (!isConnected) {
                                notifyError("Please connect wallet")
                                return
                              }
                              if (chain?.id !== chainId) {
                                notifyError(`Please connect to ${chainName[chainId]}`)
                                return
                              }
                              if (+emergencyUnstakeAmount > 100) {
                                notifyError("Please enter valid percentage")
                              } else {
                                setSPStatus("Emergency Sell in progress..");
                                emergencyUnstake()
                              }
                              // writeFunction(
                              //   "Emergency Sell ",

                              //   "emergencySale",
                              //   presaleAbi,
                              //   conAdd,
                              //   500000,
                              //   [parseEther(Samount.toString())],
                              //   0,
                              //   () => {
                              //     toast.success("Emergency Sell successful");
                              //   }
                              // );
                            }}
                          >
                            I acknowledge the {emergencyTax}% penalty
                          </button>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* {bonus && (
                  <div className="bg-white w-[22em] sm:w-[30em] xl:w-[35em] flex items-center p-2  ">
                    <FcInfo size={15} className="mr-1" />
                    <Marquee>
                      <p className="text-black font-semibold text-xs">
                        {tokenName}-Bills Bounus is live! Get {bonusPercentage}%
                        more {tokenName}
                        -Bills per BNB!
                      </p>
                    </Marquee>
                  </div>
                )} */}
                  {address && <div className="h-[2px] bg-white w-[22em] sm:w-[30em] xl:w-[35em]" />}
                  {address && <div className="flex justify-center w-full">
                    <div className="bg-[#4CA5D5] py-2 px-5 lg:py-0 w-[22em] sm:w-[30em] xl:w-[35em]">
                      <div className="flex flex-col justify-center items-center w-full pb-5">
                        <img
                          src={logo}
                          alt="logo"
                          className="w-12 h-12 lg:w-16 md:h-16"
                        />

                        <h2 className="text-xs tracking-widest uppercase">
                          Your Referral Link
                        </h2>

                        <div className="bg-[#1F1F1F] p-1 md:px-4 mt-4 rounded flex items-center justify-center ">
                          <span className="text-[8px] xs:text-[9px] xl:text-[10px] text-gray-100 tracking-wider p-2 flex-grow text-center">
                            {`https://${window.location.hostname}/${address}`}
                          </span>
                          <CopyToClipboard
                            onCopy={(e) => toast.success("Copied to clipboard")}
                            text={`https://${window.location.hostname}/${address}`}
                          >
                            <IoIosCopy className="text-xl ml-2 cursor-pointer" />
                          </CopyToClipboard>
                        </div>

                        <span className="text-xs tracking-widest uppercase mt-5">
                          COMISSIONED EARNED
                        </span>

                        <div className="bg-[#1F1F1F] py-1 px-10 mt-2 rounded flex items-center justify-center">
                          <span className="text-xs text-gray-100 tracking-wider">
                            {Number(formatEther(userReferralIncome)).toFixed(6)}
                          </span>
                        </div>

                        <span className="text-xs tracking-widest uppercase mt-5">
                          REFERRAL BONUS
                        </span>

                        <div className="bg-[#1F1F1F] py-1 px-10 mt-2 rounded flex items-center justify-center">
                          <span className="text-xs text-gray-100 tracking-wider">
                            {referralBonus}%
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>}
                  <div className="bg-lgreen w-[22em] sm:w-[30em] xl:w-[35em] border border-black"></div>
                  <div className="block p-6 bg-lgreen rounded-b-2xl mb-10 w-[22em] sm:w-[30em] xl:w-[35em] ">
                    <div className="flex flex-wrap justify-center px-6 items-center space-x-3 ">
                      <div className="flex flex-col items-center justify-center sm:mr-0 my-2">
                        <p className="text-black text-base font-bold">APR</p>
                        <div className="bg-lightgreen px-3 py-1 rounded-xl  text-center">
                          <p className="text-black font-semibold text-base">
                            {data &&
                              Number(
                                (formatEther(APR) * 365 * (60 * 60 * 24)) /
                                formatEther(data[19])
                              ).toFixed(3)}
                            %
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-col items-center ml-8 sm:ml-0  my-2">
                        <p className="text-black text-base font-bold">TVL</p>
                        <div className="bg-lightgreen px-3 py-1 rounded-xl  text-center">
                          <p className="text-black font-semibold text-base">
                            {data &&
                              Number((+formatEther(data.tokensSold) * perTokenPriceBnb * dollarPrice) + (+formatEther(data.LPTokens) * lpValueInBnb * dollarPrice)).toFixed(2)}
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-col items-center justify-center mt-6 sm:mt-0 my-2">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ResponsiveDialog
            open={openSpinner}
            title={spStatus}
          ></ResponsiveDialog>
        </div>
      </>
    )
  );
};

export default NewCard;
