import React, { useState } from "react";
import Header from "../components/Header/Header";
import FAQs from "../components/FAQs";
import Footer from "../components/Footer/Footer";
// import BugBounty from "../components/BugBounty/BugBounty";
import Old from "../components/Stake/Old";
import Button from "../components/BugBounty/Button";
import New from "../components/Stake/New";
import { useLocation } from "react-router-dom";

const Stake2 = () => {
  const [show, setShow] = useState(1);
  const {state} = useLocation()

  return (
    <>
      <div className="overflow-y-hidden main">
        <div className="bg-black/25">
          <Header />

          <div className="flex items-center justify-center">
            <Button
              text={"New"}
              onClick={() => {
                setShow(0);
              }}
              style={`mx-4 text-white ${show===0?"bg-[#4ca5d5]":""}`}
            />
            <Button
              text={"Old"}
              onClick={() => {
                setShow(1);
              }}
              style={`mx-4 text-white ${show===1?"bg-[#4ca5d5]":""}`}
            />
            <Button
              text={"Future"}
              onClick={() => {
                setShow(2);
              }}
              style={`mx-4 text-white ${show===2?"bg-[#4ca5d5]":""}`}
            />
          </div>

          {show === 0 ? (
            <div className="p-5 flex items-center justify-center my-20">
              <p className="text-red-700 text-center font-semibold p-12 bg-white/80 text-2xl rounded-lg border-red-600 border-2">
                Coming Soon
              </p>
            </div>
          ) : show === 1 ? (
            <New referrer={state}></New>
          ) : (
            <div className="p-5 flex items-center justify-center my-20">
              <p className="text-red-700 text-center font-semibold p-12 bg-white/80 text-2xl rounded-lg border-red-600 border-2">
                Coming Soon
              </p>
            </div>
          )}

          <FAQs />

          <Footer />
        </div>
      </div>
    </>
  );
};

export default Stake2;
