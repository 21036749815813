import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import providerSlice from '../providerSlice'

export const store = configureStore({
    reducer: {
        provider: providerSlice,
    },
    middleware: getDefaultMiddleware({
        serializableCheck: false
    })
})
