import React from "react";
import { slide as Menu } from "react-burger-menu";
import "./SideBar.css";
import { Link } from "react-router-dom";

const SideBar = ({params}) => {
  return (
    <Menu right>
      <div className="flex items-center justify-center overflow-y-hidden">
        <img src={"/safo1.png"} alt="logo" className="w-[5rem] ml-16 mb-5" />
      </div>
      <Link to="/" className="menu-item ">
        {/* <img
          src="/assets/telegram.png"
          alt="telegram"
          className="w-5 h-5 mr-1 inline-block"
        /> */}
        Overview
      </Link>
      <Link to="/stakes" className="menu-item">
        {/* <img
          src="/assets/nftMarketplace.png"
          alt="telegram"
          className="w-5 h-5 mr-1 inline-block"
        /> */}
        Staking
      </Link>
      <a className="menu-item">
        {/* <img
          src="/assets/twitter.png"
          alt="telegram"
          className="w-5 h-5 mr-1 inline-block"
        /> */}
        Dashboard
      </a>
      <Link to="/farms" className="menu-item">
        {/* <img
          src="/assets/launchpad.png"
          alt="telegram"
          className="w-5 h-5 mr-1 inline-block"
        /> */}
        OTC Yield Farm
      </Link>
      <a className="menu-item">
        {/* <img
          src="/assets/launchpad.png"
          alt="telegram"
          className="w-5 h-5 mr-1 inline-block"
        /> */}
        ISPO
      </a>

              <a
                href={"https://launchpad.safeonechain.com/"}
                target="_blank"
                // href="#"

                className="flex items-center text-white  cursor-pointerrounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-lyellow md:p-0 md:dark:hover:text-lyellow dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
              >
               
               SAFO Launchpad
              </a>

    </Menu>
  );
};

export default SideBar;
