import React from "react";

const FAQs = () => {
  return (
    <div className=" my-8 text-oscuro ">
      <h2 className="text-3xl text-center sm:text-left sm:text-4xl text-lyellow sm:ml-[2.2em] p-1 rounded bg-lgreen inline-block mb-10 font-semibold">
        Frequently Asked Questions
      </h2>
      <div className="flex flex-col justify-center w-full">
        <div className="flex justify-center items-center flex-col w-3/4 sm:w-6/12 mx-auto mb-5">
          {/* {faqs.map((faq, index) => { */}
          {/* return ( */}

          <div className="relative  border border-lyellow w-full">
            <input
              type="checkbox"
              id={`toggle${5}`}
              className="toggle hidden"
            />
            <label
              className="title block font-bold bg-lgreen p-4 cursor-pointer"
              htmlFor={`toggle${5}`}
            >
              What is staking?
            </label>
            <div className="content bg-[#0f054c] overflow-hidden">
              <p className="p-4">
                Staking is when you lock crypto assets for a set period of time
                to help support the operation of a blockchain. In return for
                staking your crypto, you earn more cryptocurrency.
              </p>
            </div>
          </div>
          <div className="relative  border border-lyellow w-full">
            <input
              type="checkbox"
              id={`toggle${6}`}
              className="toggle hidden"
            />
            <label
              className="title block font-bold bg-lgreen p-4 cursor-pointer"
              htmlFor={`toggle${6}`}
            >
              What is OTC yield farm?
            </label>
            <div className="content bg-[#0f054c] overflow-hidden">
              <p className="p-4">
                <strong>New style of yield-farming: SAFO-Bills</strong>

                <p>
                  - SAFO-Bills is essentially yield-farming, but the tokens
                  aren’t bought on the DEX – they are bought from the team via
                  an OTC (over the counter trade) with half of their purchase
                  being paired with purchased tokens to create an LP token, and
                  the other half going to the team.
                </p>

                <p>
                  SAFO-Bills are a unique way of rewarding investors for
                  supplying liquidity. Under the hood, a SAFO-Bill is simply a
                  SAFO-WBNB LP token.{" "}
                </p>

                <p>
                  The contract handles ALL logic, from receiving the buyers BNB,
                  calculating the token price to determine how many tokens to
                  give them, creating & staking the LP token,
                  calculating/distributing staking rewards and then
                  unstaking/burning the LP tokens at the end of the farm
                  duration.
                </p>

                <strong>
                  How does the process work? Example using a 1BNB purchase:
                </strong>
                <p>
                  Investor purchases 1BNB worth of SAFO-Bills via the
                  farming/staking dApp.
                </p>

                <p>
                  That 1BNB is split in half, .5BNB used to purchase SAFO from
                  the contract, and the other .5BNB is paired with the purchased
                  tokens to create SAFO-WBNB LP tokens.
                </p>

                <p>
                  SAFO-Bills are automatically ‘staked’ (internally – they don’t
                  leave the contract) and immediately start earning staking
                  rewards.
                </p>
                <p>
                  The investor can collect their staking rewards at anytime
                  throughout the duration of the farm.
                </p>

                <p>
                  The SAFO-Bills are locked for the duration of the farm. Once
                  the farm ends, they can unstake to redeem their SAFO-Bills.
                  This will give them back their original .5BNB, and the amount
                  of tokens they purchased with the other .5BNB.{" "}
                </p>

                <p>
                  - The contract accumulates BNB from selling SAFO-Bills, which
                  the team can collect at anytime
                </p>

                <p>
                  - The contract collects the regular ‘yield farming’ trading
                  fee’s that LP tokens receive, which the team collects at the
                  end of the farm
                </p>

                <p>
                  - users can ‘emergency unstake’ before the farm ends, but will
                  forfeit a % fee of their SAFO-Bills
                </p>

                <strong>Advantages:</strong>

                <p>
                  - Literally prints money for the team(first project earned 14
                  beans in the first 24hours) by the team collecting 50% of
                  SAFO-Bill sales. This can be used for anything: marketing, buy
                  back, buy back and burn, giveaways, etc…
                </p>

                <p>
                  - Since the contract is holding the LP tokens, the contract
                  receives the fee’s that LP tokens receive from DEX trading
                  volume. Means more money for the team!
                </p>

                <p>
                  - Introduces a LOT more liquidity into the SAFO-WBNB liquidity
                  pool – which everyone knows is very beneficial thing for
                  everyone. This liquidity is also community owned, which has
                  its appealing benefits
                </p>

                <p>
                  - Can customize features in the smart contract, as in: giving
                  a bonus token purchase amount, etc 5/10% more tokens than what
                  they would get on the market for the same price. Can turn this
                  bonus on and off, as well as modify the percentage. Can also
                  modify the early unstake fee
                </p>

                <p>- Can offer this feature to partner tokens on your dApp</p>

                <p>
                  - Can add more tokens to the contract to boost APR and sell
                  more for the bills
                </p>

                <strong>Disadvantages:</strong>

                <p>
                  - users have questioned since that they aren’t buying the
                  tokens on the market, that the price isn’t being affected.
                  This can also be viewed as a good thing, because the tokens
                  being bought & bnb are going directly INTO the liquidity pool.
                  Which reduces price impact from selling pressure
                </p>

                <p>
                  2 separate token amounts (previous teams have supplied
                  anywhere from 10%-50% of supply) funded to contract:
                </p>

                <p>
                  First, and larger, is the amount of tokens you wish to sell to
                  your investors for the SAFO-Bills.{" "}
                </p>
                <p>
                  Second, and smaller, is the staking rewards amount of tokens
                  you wish to reward and incentivize investors to purchase
                  SAFO-Bills.
                </p>

                <strong>Extra advantages:</strong>

                <p>Farm just with bnb and one click</p>
                <p>(Super fast&for newbies easy) </p>
                <p>Higher LP creation</p>
                <p>Higher yields</p>
                <p>
                  LP token Lock for full duration of yield farm (30/60/90 days){" "}
                </p>
                <p>Attractive project revenues</p>
              </p>
            </div>
          </div>
          <div className="relative  border border-lyellow w-full">
            <input
              type="checkbox"
              id={`toggle${7}`}
              className="toggle hidden"
            />
            <label
              className="title block font-bold bg-lgreen p-4 cursor-pointer"
              htmlFor={`toggle${7}`}
            >
              What are launchpad pools?
            </label>
            <div className="content bg-[#0f054c] overflow-hidden">
              <p className="p-4">
                <p>
                  Launchpad pools are the transferred launchpad presale pools that continue running here for the entire duration after a successful launch or shifted presales on the Safo Launchpad at
                  <a href="https://launchpad.safeonechain.com/" style={{marginLeft:"4px",fontWeight:"bold"}}>Launchpad.safeonechain.com</a>
                </p>
                <p>
                  Here, you can purchase additional tokens at DEX pricing, harvest your rewards, partially or completely unstake your investments, and utilize the referral system.
                </p>
              </p>
            </div>
          </div>
          {/* )
          })} */}
        </div>
      </div>
    </div>
  );
};

export default FAQs;
