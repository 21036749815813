import React from "react";
import { BsTwitter, BsTelegram } from "react-icons/bs";
const Footer = ({style}) => {
  return (
    <footer className={"text-center lg:text-left bg-lyellow text-white w-[100vw] overflow-y-hidden "+style}>
      <div className="flex items-center justify-center mt-3">
        <a
          className="text-white font-semibold"
          href="https://Twitter.com/safeone_chain"
          target={`_blank`}
        >
          <BsTwitter className="text-2xl mx-2" />
        </a>
        <a
          className="text-white font-semibold"
          href="https://t.me/safeonechain"
          target={`_blank`}
        >
          <BsTelegram className="text-2xl mx-2" />
        </a>
      </div>
      <div className="text-center py-4 bg-black]">
        2023 @{" "}
        <a
          className="text-white font-semibold"
          target={`_blank`}
        >
          All Rights Reserved.
        </a>
        <br />
      </div>
    </footer>
  );
};

export default Footer;
