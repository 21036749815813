import React from "react";
import { Link } from "react-router-dom";

const Error = () => {
  return (
    <div className="flex items-center justify-center w-screen h-screen">
      <div className="flex justify-center items-center px-10 py-20 sm:px-40 bg-black/50 rounded-md shadow-xl">
        <div className="flex flex-col items-center w-full">
          <h1 className="font-bold text-lyellow text-9xl">404</h1>

          <h6 className="mb-2 text-2xl font-bold text-center text-white md:text-3xl">
            <span className="text-red-500">Oops!</span> Page not found
          </h6>

          <p className="mb-8 text-center text-white md:text-lg">
            The page you’re looking for doesn’t exist.
          </p>

          <Link to="/" className="bg-lyellow text-md font-semibold py-2 px-[3.6em] hover:bg-white hover:text-black transition ease-in duration-200 rounded-xl">
            Go home
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Error;