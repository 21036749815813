import React, { useEffect, useState } from "react";
import {
  AiFillExclamationCircle,
  AiFillTwitterCircle,
  AiOutlineChrome,
  AiOutlineFileDone,
} from "react-icons/ai";
import { FcInfo } from "react-icons/fc";
import { useSelector } from "react-redux";
import { formatEther, formatUnits } from "ethers/lib/utils";
import axios from "axios";
import { ethers } from "ethers";
import { tokenAbi } from "../../constants/tokenAbi";
import { pairAbi } from "../../constants/pairAbi";
import moment from "moment";
import { notifyError, notifySuccess } from "../../utils/toastError";
import Buy from "./Buy";
import Button from "./Button";
import Marquee from "react-fast-marquee";
import { FaChartLine, FaTelegram } from "react-icons/fa";

const OldCard = ({
  tokenName,
  contractAddress,
  abi,
  logo,
  website,
  bills,
  token,
}) => {
  const Rpc_Url = "https://bsc-dataseed.binance.org/";

  const { connect, accountAddress, signer,provider } = useSelector(
    (state) => state.provider
  );

  const [loader, setLoader] = useState(false);
  const [contract, setContract] = useState(null);

  const [bnbBalance, setBalance] = useState(0);
  const [bnbRate, setBnbRate] = useState(0);
  const [accountTokenBalance, setAccountTokenBalance] = useState(0);
  const [accountTokenContractBalance, setAccountTokenContractBalance] =
    useState(0);
  const [pendingRewards, setPendingRewards] = useState(0);
  const [paidRewards, setPaidRewards] = useState(0);
  const [priceTokenBnb, setPriceTokenBnb] = useState(0);
  const [startDate, setStartDate] = useState(1680089435);
  const [endDate, setEndDate] = useState(1680089435);
  const [days, setDays] = useState(0);
  const [remiangDays, setRemaingDays] = useState(0);

  const [bonus, setSetBonus] = useState(false);
  const [bonusPercentage, setSetBonusPercentage] = useState(0);
  const [earlyUnstakefee, setearlyUnstakefee] = useState(0);
  const [tvl, setTvl] = useState(0);
  const [apr, setApr] = useState(0);


  const [oldUser, setOldUser] = useState(true);
  const [showBuy, setshowBuy] = useState(false);

  const [showAlert, setshowAlert] = useState(false);

  const initContracts = async () => {
    if (!connect) {
      setContract(
        new ethers.Contract(
          contractAddress,
          abi,
          new ethers.providers.JsonRpcProvider(Rpc_Url)
        )
      );
    } else {
      setContract(new ethers.Contract(contractAddress, abi, signer));
    }
  };

  useEffect(() => {
    // console.log("contract", contractAddress);
    initContracts();
  }, [connect]);

  const init = async () => {
    const {
      data: { USD },
    } = await axios.get(
      `https://min-api.cryptocompare.com/data/price?fsym=BNB&tsyms=USD`
    );

    setBnbRate(USD);
    const _rewardTokenAddress = await contract.rewardToken();
    const _stakeTokenAddress = await contract.stakedToken();
    const _startDate = await contract.poolStartTime();
    const _endDate = await contract.poolEndTime();
    const _bonus = await contract.kiwiBillBonusActive();
    const _bonusPercentage = await contract.kiwiBillBonus();
    const _earlyFee = await contract.earlyUnstakeFee();
    

    setSetBonusPercentage((+_bonusPercentage.toString() / 10000) * 100);

    setSetBonus(_bonus);
    setearlyUnstakefee((+_earlyFee.toString() / 10000) * 100);
    setStartDate(_startDate.toString());
    setEndDate(_endDate.toString());

    const _days = Math.abs(+_startDate - +_endDate) / 60 / 60 / 24;
    const _remaingDays =
      Math.abs(+_startDate - +moment().unix()) / 60 / 60 / 24;
    // console.log("remaingDays", _remaingDays);
    setRemaingDays(+_remaingDays.toFixed(1));
    setDays(_days);

    const tokenContract = new ethers.Contract(
      _rewardTokenAddress,
      tokenAbi,
      new ethers.providers.JsonRpcProvider(Rpc_Url)
    );
    const pairContract = new ethers.Contract(
      _stakeTokenAddress,
      pairAbi,
      new ethers.providers.JsonRpcProvider(Rpc_Url)
    );

    const reserves = await pairContract.getReserves();
    const tokenDecimals = await tokenContract.decimals();
    const _apr = await contract.rewardRate();
    setApr(+formatUnits(_apr.toString(), tokenDecimals.toString()));
    if (tokenName === "QBE") {
      const _tvl = await contract.totalKiwiOwed();

      setTvl(+formatUnits(_tvl.toString(), tokenDecimals.toString()));

      
    } else if (tokenName === "SAFO") {
      const _tvl = await contract.totalStaked();

      setTvl(+formatUnits(_tvl.toString(), tokenDecimals.toString()));
    }

    const pariTokenBlance = +formatUnits(
      reserves._reserve1.toString(),
      tokenDecimals.toString()
    );
    const pairBnbBlance = +formatEther(reserves._reserve0.toString());

    const price = pairBnbBlance / pariTokenBlance;

    setPriceTokenBnb(price);

    if (accountAddress) {
      // provider.getBalance(accountAddress).then((balance) => {
      //   // convert a currency unit from wei to ether
      //   const balanceInEth = ethers.utils.formatEther(balance)
      //   // console.log(`balance: ${balanceInEth} BNB`)
      //   setBalance(+balanceInEth);
      //  })
      const _accountTokenBalance = await tokenContract.balanceOf(
        accountAddress
      );
      setAccountTokenBalance(
        +formatUnits(_accountTokenBalance.toString(), tokenDecimals.toString())
      );



      const _accountTokenContractBalance = await contract.addressToUserInfo(
        accountAddress
      );
      setAccountTokenContractBalance(
        +formatUnits(
          _accountTokenContractBalance.kiwiBalance.toString(),
          tokenDecimals.toString()
        )
      );

      setBalance(+formatUnits(_accountTokenContractBalance.bnbBalance.toString()));


      // setBalance(
      //   +formatEther(_accountTokenContractBalance.bnbBalance.toString())
      // );

      const _pendigRewards = await contract.earned(accountAddress);

      // console.log("pendigRewards", _pendigRewards.toString());

      setPendingRewards(
        +formatUnits(_pendigRewards.toString(), tokenDecimals.toString())
      );

      const _paidRewards = await contract.userPaidRewards(accountAddress);

      //  console.log("paidRewards", formatUnits(_paidRewards.toString(),tokenDecimals.toString()));

      setPaidRewards(
        +formatUnits(_paidRewards.toString(), tokenDecimals.toString())
      );
    }
  };

  useEffect(() => {
    if (contract) {
      init();
    }
  }, [accountAddress, connect, contract]);

  const claimRewards = async () => {
    if (connect) {
      try {
        await contract.functions.claimRewards().then((tx) => {
          return (
            tx.wait(1).then((receipt) => {
              console.log("Transaction receipt:", receipt);
              init();
              setLoader(false);
              notifySuccess("Rewards Claimed Successfully");
            }),
            (error) => {
              return error.checkCall().then((error) => {
                console.log("Error", error);
                setLoader(false);
                notifyError(error.message);
              });
            }
          );
        });
      } catch (error) {
        setLoader(false);
        if (error.reason) {
          console.log(error.reason);
          notifyError(error.reason);
        } else {
          console.log(error.message);
          notifyError(error.message);
        }
      }
    } else {
      notifyError("Please Connect Wallet ");
    }
  };

  const emergencyUnStake = async () => {
    if (connect) {
      try {
        await contract.functions.emergencyUnstake().then((tx) => {
          return (
            tx.wait(1).then((receipt) => {
              console.log("Transaction receipt:", receipt);
              init();
              setLoader(false);
              notifySuccess("Unstake Tokens Successfully");
            }),
            (error) => {
              return error.checkCall().then((error) => {
                console.log("Error", error);
                setLoader(false);
                notifyError(error.message);
              });
            }
          );
        });
      } catch (error) {
        setLoader(false);
        if (error.reason) {
          console.log(error.reason);
          notifyError(error.reason);
        } else {
          console.log(error.message);
          notifyError(error.message);
        }
      }
    } else {
      notifyError("Please Connect Wallet ");
    }
  };

  const unStake = async () => {
    if (connect) {
      try {
        await contract.functions.redeemKiwiBill().then((tx) => {
          return (
            tx.wait(1).then((receipt) => {
              console.log("Transaction receipt:", receipt);
              setLoader(false);
              init();
              notifySuccess("Unstake Tokens Successfully");
            }),
            (error) => {
              return error.checkCall().then((error) => {
                console.log("Error", error);
                setLoader(false);
                notifyError(error.message);
              });
            }
          );
        });
      } catch (error) {
        setLoader(false);
        if (error.reason) {
          console.log(error.reason);
          notifyError(error.reason);
        } else {
          console.log(error.message);
          notifyError(error.message);
        }
      }
    } else {
      notifyError("Please Connect Wallet ");
    }
  };

  const handleBuy = () => {
    setshowBuy(!showBuy);
  };

  const handleAlert = () => {
    setshowAlert(!showAlert);
  };

  const Row = ({ text, value, price }) => (
    <div className="flex justify-between items-center">
      <p className="text-lyellow text-xl font-semibold w-[50%]">{text}:</p>
      <div className="bg-lightgreen px-8  py-1 rounded-xl flex flex-wrap w-56 justify-between items-center">
        <p className="text-black font-semibold text-base text-center">
          {value}
        </p>
        <p className="text-black text-base text-center">-${price}</p>
      </div>
    </div>
  );

  return (
    <>
      <div className="w-full flex flex-col justify-between">
        <div className="flex justify-evenly flex-col md:flex-row mt-8">
          <div className="">
            <div className="space-y-4 text-white">
              <div className="flex flex-col items-center justify-between px-[5em] pb-5 pt-2">
                <div className="relative block px-6 py-4 bg-lyellow rounded-t-2xl border border-black/20 shadow-md w-[22em] sm:w-[30em] xl:w-[35em] ">
                  <div className="flex items-center">
                    <div className="flex flex-wrap justify-between items-center w-[100%]">
                      <div>
                        <p className="text-white text-xl font-semibold">
                          Earn {tokenName}
                        </p>
                        <p className="text-white text-sm italic">
                          stake {tokenName}-Bills
                        </p>
                      </div>
                      <div className="mt-2 sm:none">
                        <p className="text-white text-sm">
                          Start: {moment.unix(startDate).utc().format("lll")}
                        </p>
                        <p className="text-white text-sm">
                          End: {moment.unix(endDate).utc().format("lll")}
                        </p>
                        <p className="text-white text-sm">
                          Duration: {days} days
                        </p>
                        <p className="text-white text-sm">
                          Remaining:{" "}
                          {days > remiangDays ? parseInt(days - remiangDays) : 0} days
                        </p>
                      </div>
                      <img
                        className="w-16 h-16 rounded-full hidden sm:block "
                        src={logo}
                        alt={tokenName}
                      />
                    </div>
                    <img
                      className="w-16 h-16 rounded-full  sm:hidden"
                      src={logo}
                      alt={tokenName}
                    />
                  </div>
                  <div className="absolute right-0 top-0 bg-lightgreen py-[2px] px-2 rounded-sm">
                    <p className="text-white text-sm">Old!</p>
                  </div>
                </div>

                <div className="block px-6 py-3 bg-lgreen w-[22em] sm:w-[30em] xl:w-[35em] ">
                  <div className="flex items-center justify-center">
                    <div className=" text-white">
                      <a
                        className="flex items-center justify-center"
                        href={website}
                        target="_blank"
                      >
                        <p className="text-white text-sm mr-[4px]">
                          {tokenName}
                        </p>
                        <AiOutlineChrome size={15} />
                      </a>
                    </div>
                    <p className="mx-2 text-sm text-white">/</p>
                    <div className=" text-white">
                      <a
                        className="flex items-center justify-center"
                        href={bills}
                        target="_blank"
                      >
                        <p className="text-white text-sm mr-[4px]">
                          {tokenName}-Bills
                        </p>
                        <AiOutlineFileDone size={15} />
                      </a>
                    </div>
                    <p className="mx-2 text-sm text-white">/</p>
                    <div className=" text-white">
                      <a
                        className="flex items-center justify-center"
                        href={token}
                        target="_blank"
                      >
                        <p className="text-white text-sm mr-[4px]">
                          {tokenName}
                        </p>
                        <AiOutlineFileDone size={15} />
                      </a>
                    </div>
                  </div>
                  <div className="flex flex-col justify-center space-y-5 mt-6">
                    <Row
                      text={"BNB Balance"}
                      value={bnbBalance.toFixed(3)}
                      price={(bnbRate * +bnbBalance).toFixed(2)}
                    />
                    <Row
                      text={`${tokenName} Balance`}
                      value={accountTokenBalance.toFixed(2)}
                      price={(
                        accountTokenBalance *
                        priceTokenBnb *
                        bnbRate
                      ).toFixed(2)}
                    />
                    <Row
                      text={`${tokenName}-Bills Staked`}
                      value={accountTokenContractBalance.toFixed(2)}
                      price={(
                        accountTokenContractBalance *
                        priceTokenBnb *
                        bnbRate
                      ).toFixed(2)}
                    />
                    <Row
                      text={"Pending Rewards"}
                      value={pendingRewards.toFixed(2)}
                      price={(pendingRewards * priceTokenBnb * bnbRate).toFixed(
                        2
                      )}
                    />

                    <Row
                      text={"Paid Rewards"}
                      value={paidRewards.toFixed(2)}
                      price={(paidRewards * priceTokenBnb * bnbRate).toFixed(2)}
                    />
                  </div>
                  {!showBuy && !showAlert && (
                    <div className="flex items-center justify-center flex-wrap px-5 mt-8">
                      {/* <div className="m-2 ">
                        <Button
                          text={`Purchase ${tokenName}-Bills!`}
                          // onClick={handleBuy}
                        />
                      </div> */}
                      <Button text={"Collect Rewards"} onClick={claimRewards} />
                    </div>
                  )}

                  {showAlert && (
                    <div className="space-y-4 pt-4">
                      <div className="bg-[#fffbe7] border rounded flex items-center p-1">
                        <AiFillExclamationCircle
                          size={35}
                          className="mr-1 text-[#f2b233] text-xl"
                        />
                        <p className="text-black font-semibold text-center text-xs">
                          Your staked tokens are locked until:{" "}
                          {moment.unix(endDate).utc().format("lll")}. If you
                          unstake before this date, you will be penalized{" "}
                          {earlyUnstakefee}% of your staked tokens.
                        </p>
                      </div>
                      <div className="flex flex-wrap-reverse items-center justify-center sm:justify-between">
                        <Button text={"Cancel"} onClick={handleAlert} />
                        <button
                          className={`text-sm font-semibold mb-3 sm:mb-0 py-2 px-4 rounded-lg border-dashed border-red-600 border-2 bg-red-300 text-red-600 `}
                          onClick={emergencyUnStake}
                        >
                          I acknowledge the {earlyUnstakefee}% penalty
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                {showBuy && (
                  <Buy
                    handleBuy={handleBuy}
                    oldUser={oldUser}
                    init={init}
                    contract={contract}
                  />
                )}
                {bonus && (
                  <div className="bg-white w-[22em] sm:w-[30em] xl:w-[35em] flex items-center p-2  ">
                    <FcInfo size={15} className="mr-1" />
                    <Marquee>
                      <p className="text-black font-semibold text-xs">
                        {tokenName}-Bills Bounus is live! Get {bonusPercentage}%
                        more {tokenName}
                        -Bills per BNB!
                      </p>
                    </Marquee>
                  </div>
                )}
                <div className="bg-lgreen w-[22em] sm:w-[30em] xl:w-[35em] border border-black"></div>
                <div className="block p-6 bg-lgreen rounded-b-2xl mb-10 w-[22em] sm:w-[30em] xl:w-[35em] ">
                  <div className="flex flex-wrap justify-center px-6 items-center space-x-3 ">
                    <div className="flex flex-col items-center justify-center sm:mr-0 my-2">
                      <p className="text-black text-base font-bold">APR</p>
                      <div className="bg-lightgreen px-3 py-1 rounded-xl  text-center">
                        <p className="text-black font-semibold text-base">
                          {parseInt(+apr.toFixed(2) * 100)}%
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col items-center ml-8 sm:ml-0  my-2">
                      <p className="text-black text-base font-bold">TVL</p>
                      <div className="bg-lightgreen px-3 py-1 rounded-xl  text-center">
                        <p className="text-black font-semibold text-base">
                          {parseInt(tvl)}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col items-center justify-center mt-6 sm:mt-0 my-2">
                      <div className="mb-2">
                        <Button
                          text={"Unstake"}
                          onClick={unStake}
                          style={
                            +endDate > moment().unix()
                              ? `bg-[#dedede] shadow`
                              : ""
                          }
                          disabled={+endDate > moment().unix()}
                        />
                      </div>
                      <Button
                        text={"Emergency Unstake"}
                        onClick={handleAlert}
                        style={
                          +endDate < moment().unix()
                            ? `bg-[#dedede] shadow`
                            : ""
                        }
                        disabled={+endDate < moment().unix()}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OldCard;
