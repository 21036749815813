import React from "react";

const Button = ({text,onClick,style,disabled}) => {
  return (
    <div className="flex justify-start items-center">
      <button
        className={`bg-lyellow text-sm font-semibold py-2 px-4 text-whit rounded-lg ${style}`}
        onClick={onClick}
        disabled={disabled}
      >
        {text}
      </button>
    </div>
  );
};

export default Button;
