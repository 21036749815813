import React, { useState } from "react";
import BugBountyCard from "./BugBountyCard";
import { NewSafoAbi, NewSafoAddress } from "../../constants/New/safo";
import Safo from "./Safo";
import { NewQbeAbi, NewQbeAddress } from "../../constants/New/qbe";
import Qbe from "./Qbe";
import { lumiLogo, qbeLogo, safoLogo } from "../../images";
import { farmAbi } from "../../constants/farmAbi";

const BugBounty = ({referralAddress}) => {
  const [bugBounties, setbugBounties] = useState([]);

  // const host = "https://admin-d.herokuapp.com
  console.log("referralAddress", referralAddress)
  return (
    <>
      <div className=" flex flex-col justify-center items-center md:grid md:grid-cols-1 lg:grid-cols-2 ">
        {bugBounties.length > 0 ? (
          bugBounties.map((bugbounty) => (
            <BugBountyCard key={bugbounty._id} {...bugbounty} />
          ))
        ) : (
          <>
            
             <Safo
              tokenName="SAFO"
              contractAddress={"0x38214D932b343Ed15F261B04ff2cAA529D842287"}
              abi={farmAbi}
              logo={safoLogo}
              website={"https://safeonechain.com"}
              bills={"https://bscscan.com/address/0x38214D932b343Ed15F261B04ff2cAA529D842287"}
              token={"https://bscscan.com/token/0x9320bdB3C8F3d0B1313726EFbB0f0061EBf149aD"} 
              chainId={56}
              referralAddress={referralAddress}
            />
            <Safo
              tokenName="LFD"
              contractAddress={"0xCb691cf28355deE67C915C401d11eb996B6BC505"}
              abi={farmAbi}
              logo={lumiLogo}
              website={"https://lumi-foundation.com/"}
              bills={"https://bscscan.com/address/0xCb691cf28355deE67C915C401d11eb996B6BC505"}
              token={"https://bscscan.com/token/0x43dA25595b4b8BbceB2279d9f77EBcAa337044E8"} 
              chainId={56}
              referralAddress={referralAddress}
            />
            {/* <Qbe
              tokenName="QBE"
              contractAddress={NewQbeAddress}
              abi={NewQbeAbi}
              logo={qbeLogo}
              website={"https://qbe-ecosystem.com"}
              bills={"https://bscscan.com/address/0x937723f8921Ec37593a551F4bf27F27947028926"}
              token={"https://bscscan.com/token/0xe13e2b3e521080e539260d1087c087582d1bc501"}
            /> */}
            {/* <BugBountyCard
              tokenName="QBE"
              // contractAddress={"0x3Ead9DA20F4B6a0fA794C30bA9328a7CBc5D8D82"}
              logo={"/qbe.jpg"}
              // telegram={"https://t.me/PulsinatorBSC"}
              website={"https://qbe-ecosystem.com"}
              bills={"https://bscscan.com/token/0xe13e2b3e521080e539260d1087c087582d1bc501"}
              token={"https://bscscan.com/token/0xe13e2b3e521080e539260d1087c087582d1bc501"}

              // dapp={"https://pulsinator.app/"}
              // chart={
              //   "https://www.dextools.io/app/en/bnb/pair-explorer/0xa26b7cc2d1c18ec9cb1d57b3f7d74a06e5df4740"
              // }
              // twitter={""}
              // showTwitter={false}
            /> */}
            {/* <BugBountyCard
              tokenName="KUZCO INU"
              // contractAddress={"0x1d7e52d18b69260f890E28AC73Bf723C8c042A5E"}
              logo={"/kuzcoInu.jpg"}
              // telegram={"https://t.me/PulsinatorBSC"}
              website={"https://kuzcoinu.com/"}
              bills={"https://bscscan.com/token/0x7df332d3183db400b69b71d4b6bcd0354293cdd9"}
              token={"https://bscscan.com/token/0x7df332d3183db400b69b71d4b6bcd0354293cdd9"}
              // dapp={
              //   "https://pulsinator.app/#/main/dashboard/Pulsinator%20boost"
              // }
              // chart={
              //   "https://poocoin.app/tokens/0xc5c56e7a5d2d8d5d48c362043845c6fe12f01b97"
              // }
              // twitter={""}
              // showTwitter={false}
            /> */}
          </>
        )}
      </div>
      <div className="flex justify-center items-center">
        <div className="w-11/12 h-[0.2px] border-t border-lyellow" />
      </div>
    </>
  );
};

export default BugBounty;
