import { ethers } from "ethers";
import { parseUnits } from "ethers/lib/utils";
import React from "react";
import { useSelector } from "react-redux";
import { abi } from "../../constants/abi";
import { notifyError, notifySuccess } from "../../utils/toastError";
import Button from "./Button";

const Buy = ({ handleBuy, oldUser, init,contract }) => {
  const { connect } = useSelector(
    (state) => state.provider
  );
  const [loader, setLoader] = React.useState(false);
  const [amount, setAmount] = React.useState("");
  const [refferalWalletAddress, setRefferalWalletAddress] = React.useState("");

  const buyToken = async () => {
    if (connect) {
      if(refferalWalletAddress.length>0){
        if(refferalWalletAddress.length!==42){
          notifyError("Invalid Refferal Address");
          return;
        }
      }
      try {
        // console.log(signer);

        // console.log(contract.address);
        const options = { value: ethers.utils.parseEther(amount.toString()) };

        await contract
          .purchaseTokenBond(
            oldUser
              ? "0x0000000000000000000000000000000000000000"
              : refferalWalletAddress.length>0?refferalWalletAddress:"0x0000000000000000000000000000000000000000",
            options
          )
          .then((tx) => {
            return (
              tx.wait(1).then((receipt) => {
                console.log("Transaction receipt:", receipt);
                setLoader(false);
                notifySuccess("Tokens Purchased Successfully");
                init();
                handleBuy();
              }),
              (error) => {
                return error.checkCall().then((error) => {
                  console.log("Error", error);
                  setLoader(false);
                  notifyError(error.message);
                });
              }
            );
          });
      } catch (error) {
        setLoader(false);
        if (error.reason) {
          console.log(error.reason);
          notifyError(error.reason);
        } else {
          console.log(error.message);
          notifyError(error.message);
        }
      }
    } else {
      notifyError("Please Connect Your Wallet");
    }
  };
  return (
    <div className="bg-lgreen w-[22em] sm:w-[30em] xl:w-[35em] py-4 ">
      <div className="flex justify-center mb-3">
        <p className="text-lyellow text-lg font-semibold w-[40%]">Buy Amount</p>
        <input
          type="text"
          placeholder="Enter Bnb Amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          className="text-black border-lightgreen border-2 rounded-xl px-4 text-sm w-[50%]"
        />
      </div>

      {!oldUser && (
        <div className="flex justify-center mb-2">
          <p className="text-lyellow text-lg font-semibold w-[40%]">
            Refferal Wallet Address
          </p>
          <input
            type="text"
            placeholder="Enter Wallet Address... (Optional) "
            value={refferalWalletAddress}
            onChange={(e) => setRefferalWalletAddress(e.target.value)}
            className="text-black border-lightgreen border-2 rounded-xl px-4 h-7 text-sm w-[50%]"
          />
        </div>
      )}

      <div className="flex justify-center">
        <Button text="Buy" 
        // onClick={buyToken}
         />
        <div className="ml-4">
          <Button text="Close" onClick={handleBuy} />
        </div>
      </div>
    </div>
  );
};

export default Buy;
