import React, { useEffect, useState } from "react";
import Web3 from "web3";
import { LaunchPadABI, launchPadAdd, rpc } from "../../config";
import NewCard from "./NewCard";

const New = ({ referrer }) => {

  const chains = [56, 1]

  // const chain = chainId?.chainId ? chainId?.chainId : chainidSelected;
  // const web3 = new Web3(new Web3.providers.HttpProvider(rpc[chain]));
  // const contractR = new web3.eth.Contract(LaunchPadABI, launchPadAdd[chain]);
  const [farmArray, setFarmArray] = useState();

  useEffect(() => {
    const abc = async () => {
      const data = await Promise.all(chains.map(async (chain, e) => {
        const web3 = new Web3(new Web3.providers.HttpProvider(rpc[chain]));
        const contractR = new web3.eth.Contract(LaunchPadABI, launchPadAdd[chain]);
        const _farm = await contractR.methods.getFarmArray().call();
        console.log("_farm", _farm)
        // setFarmArray(_farm);
        return { chainId: chain, _farmArray: _farm }
      }))
      console.log("data", data)
      // const _farm = await contractR.methods.getFarmArray().call();
      // console.log("_farm", _farm)
      setFarmArray(data);
    };

    abc();
  }, []);


  return (
    <>
      <div className=" flex flex-col justify-center items-center md:grid md:grid-cols-1 lg:grid-cols-2 ">
        {farmArray && farmArray.map(({ chainId, _farmArray }, e) => {
          if (_farmArray.length > 0) {
            console.log("_farmArray", _farmArray)
            return (
              <>
                {
                  _farmArray.map((farm, i) => {
                    return (
                      <NewCard key={i} conAdd={farm} referrer={referrer} chainId={chainId} />
                    )
                  })
                }
              </>
            )
          } else {
            return null
          }
        })}


      </div>
      <div className="flex justify-center items-center">
        <div className="w-11/12 h-[0.2px] border-t border-lyellow" />
      </div>
    </>
  );
};

export default New;
