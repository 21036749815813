import { ethers } from "ethers";
import React from "react";
import { notifyError, notifySuccess } from "../../utils/toastError";
import Button from "./Button";
import { useAccount, useContractWrite, useNetwork, useWaitForTransaction } from "wagmi";
import { abi } from "../../constants/abi";

const Buy = ({ handleBuy, oldUser, init, contractAddress, refAddress, chainId }) => {

  const [amount, setAmount] = React.useState("");
  const [refferalWalletAddress, setRefferalWalletAddress] = React.useState(refAddress || "");
  const { isConnected } = useAccount();
  const { chain } = useNetwork();

  // console.log("refAddress", refAddress);
  // console.log("refferalWalletAddress", refferalWalletAddress)
  // console.log("oldUser", oldUser)


  const { write: buyToken, data: unstakeData } = useContractWrite({
    address: contractAddress,
    abi: abi,
    functionName: 'purchaseTokenBond',
    args: [oldUser
      ? "0x0000000000000000000000000000000000000000"
      : refferalWalletAddress.length > 0 ? refferalWalletAddress : "0x0000000000000000000000000000000000000000",],
    value: amount.length > 0 ? ethers.utils.parseEther(amount.toString()).toString() : "0",
    onError: (error) => {
      var str = JSON.stringify(error)
      var obj = JSON.parse(str)
      var reason = obj.cause.reason
      reason ? notifyError(`${reason}`) : notifyError(`${obj?.details}`)
    }
  })

  const unstakeTx = useWaitForTransaction({
    hash: unstakeData?.hash,
    onSuccess: () => {
      notifySuccess("Tokens Purchased Successfully");
      init();
      handleBuy();
    }

  })

  return (
    <div className="bg-lgreen w-[24em] sm:w-[30em] xl:w-[35em] py-4 ">
      <div className="flex justify-center mb-3">
        <p className="text-lyellow text-lg font-semibold w-[40%]">Buy Amount</p>
        <input
          type="number"
          placeholder="Enter Bnb Amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          className="text-black border-lightgreen border-2 rounded-xl px-4 text-sm w-[50%]"
        />
      </div>

      {/* {!oldUser && (
        <div className="flex justify-center mb-2">
          <p className="text-lyellow text-lg font-semibold w-[40%]">
            Refferal Wallet Address
          </p>
          <input
            type="text"
            placeholder="Enter Wallet Address... (Optional) "
            value={refferalWalletAddress}
            onChange={(e) => setRefferalWalletAddress(e.target.value)}
            className="text-black border-lightgreen border-2 rounded-xl px-4 h-7 text-sm w-[50%]"
          />
        </div>
      )} */}

      <div className="flex justify-center">
        <Button text="Buy" onClick={() => {
          if (isConnected) {
            if (refferalWalletAddress.length > 0) {
              if (refferalWalletAddress.length !== 42) {
                notifyError("Invalid Refferal Address");
                return;
              }
            }
            if (chain.id === chainId) {
              buyToken()
            } else {
              notifyError(`Please Connect To ${chainId === 56 ? "BSC Mainnet" : chainId === 1 ? "ETH Mainnet" : "BSC Testnet"} Network`)
            }

          } else {
            notifyError("Please Connect Your Wallet");
          }
        }} />
        <div className="ml-4">
          <Button text="Close" onClick={handleBuy} />
        </div>
      </div>
    </div>
  );
};

export default Buy;
