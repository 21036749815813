import axios from "axios";
import { ethers } from "ethers";
import { formatEther, formatUnits } from "ethers/lib/utils";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import {
  AiFillExclamationCircle,
  AiOutlineChrome,
  AiOutlineFileDone
} from "react-icons/ai";
import { FcInfo } from "react-icons/fc";
import { useAccount, useBalance, useContractWrite, useNetwork, useWaitForTransaction } from "wagmi";
import { pairAbi } from "../../constants/pairAbi";
import { tokenAbi } from "../../constants/tokenAbi";
import { notifyError, notifySuccess } from "../../utils/toastError";
import Button from "./Button";
import Buy from "./BuySafo";
import { parseEther } from "viem";
import CopyToClipboard from "react-copy-to-clipboard";
import logo1 from "../../assets/images/global/logo.svg";
import { IoIosCopy } from "react-icons/io";
import { toast } from "react-toastify";

const Safo = ({
  tokenName,
  contractAddress,
  abi,
  logo,
  website,
  bills,
  token,
  chainId,
  referralAddress
}) => {

  const urls = {
    56: "https://bsc-dataseed.binance.org/",
    97: "https://data-seed-prebsc-1-s1.binance.org:8545",
    1: "https://eth-mainnet.g.alchemy.com/v2/M-kyrjFQxhhibwoMymhnOSbs0IQEYaP5"
  }

  const tokenSymbol = {
    56: "BNB",
    97: "BNB",
    1: "ETH"
  }

  const nativeSymbol = tokenSymbol[chainId];

  const Rpc_Url = urls[chainId];

  const { address, isConnected } = useAccount();
  const { chain } = useNetwork();
  const { data } = useBalance({
    address: address,
    chainId: chainId,
  });

  // console.log("bnb balance", data)
  const [emergencyUnstakeAmount, setEmergencyUnstakeAmount] = useState("0");
  const [unstakeAmount, setUnstakeAmount] = useState("0");


  const [loader, setLoader] = useState(false);
  const [contract, setContract] = useState(null);

  const [bnbBalance, setBalance] = useState(0);
  const [bnbRate, setBnbRate] = useState(0);
  const [accountTokenBalance, setAccountTokenBalance] = useState(0);
  const [accountTokenContractBalance, setAccountTokenContractBalance] =
    useState(0);
  const [pendingRewards, setPendingRewards] = useState(0);
  const [paidRewards, setPaidRewards] = useState(0);
  const [priceTokenBnb, setPriceTokenBnb] = useState(0);
  const [startDate, setStartDate] = useState(1680089435);
  const [endDate, setEndDate] = useState(1680089435);
  const [days, setDays] = useState(0);
  const [remiangDays, setRemaingDays] = useState(0);

  const [bonus, setSetBonus] = useState(false);
  const [bonusPercentage, setSetBonusPercentage] = useState(0);
  const [earlyUnstakefee, setearlyUnstakefee] = useState(0);
  const [tvl, setTvl] = useState(0);
  const [apr, setApr] = useState(0);
  const [referralBonusPercentage, setReferralBonusPercentage] = useState(0);
  const [comissionEarned, setComissionEarned] = useState(0);
  const [lpValueInBnb, setLpValueInBnb] = useState(0);
  const [totalTokensLocked, setTotalTokensLocked] = useState(0);
  const [totalLpLocked, setTotalLpLocked] = useState(0);






  const [oldUser, setOldUser] = useState(false);
  const [showBuy, setshowBuy] = useState(false);

  const [showAlert, setshowAlert] = useState(false);
  const [showUnstakeAlert, setShowUnstakeAlert] = useState(false);


  const initContracts = async () => {
    setContract(
      new ethers.Contract(
        contractAddress,
        abi,
        new ethers.providers.JsonRpcProvider(Rpc_Url)
      )
    );

  };

  useEffect(() => {
    initContracts();
  }, []);

  const init = async () => {
    const {
      data: { USD },
    } = await axios.get(
      `https://min-api.cryptocompare.com/data/price?fsym=${nativeSymbol}&tsyms=USD`
    );

    setBnbRate(USD);
    const _rewardTokenAddress = await contract.rewardToken();
    const _stakeTokenAddress = await contract.stakedToken();
    const _WETH = await contract.WETH();

    const _startDate = await contract.poolStartTime();
    const _endDate = await contract.poolEndTime();
    const _bonus = await contract.tokenBondBonusActive();
    const _bonusPercentage = await contract.tokenBondBonus();
    const _earlyFee = await contract.earlyUnstakeFee();
    const _referralBonus = await contract.referralPercentage();


    setReferralBonusPercentage((+_referralBonus.toString() / 10000) * 100);

    setSetBonusPercentage((+_bonusPercentage.toString() / 10000) * 100);

    setSetBonus(_bonus);
    setearlyUnstakefee((+_earlyFee.toString() / 10000) * 100);
    setStartDate(_startDate.toString());
    setEndDate(_endDate.toString());

    const _days = Math.abs(+_startDate - +_endDate) / 60 / 60 / 24;
    const _remaingDays =
      Math.abs(+_startDate - +moment().unix()) / 60 / 60 / 24;
    setRemaingDays(+_remaingDays.toFixed(1));
    setDays(_days);

    const tokenContract = new ethers.Contract(
      _rewardTokenAddress,
      tokenAbi,
      new ethers.providers.JsonRpcProvider(Rpc_Url)
    );

    const wethContract = new ethers.Contract(
      _WETH,
      tokenAbi,
      new ethers.providers.JsonRpcProvider(Rpc_Url)
    );
    const pairContract = new ethers.Contract(
      _stakeTokenAddress,
      pairAbi,
      new ethers.providers.JsonRpcProvider(Rpc_Url)
    );

    // console.log("_stakeTokenAddress", _stakeTokenAddress)

    const reserve0 = await tokenContract.balanceOf(_stakeTokenAddress);
    const reserve1 = await wethContract.balanceOf(_stakeTokenAddress);

    const totalSupply = await pairContract.totalSupply();

    // console.log("totalSupply", +reserve1/+totalSupply)

    setLpValueInBnb(+reserve1 / +totalSupply)


    // const reserves = await pairContract.getReserves();
    const tokenDecimals = await tokenContract.decimals();

    const pariTokenBlance = +formatUnits(
      reserve0.toString(),
      tokenDecimals.toString()
    );
    const pairBnbBlance = +formatEther(reserve1.toString());

    const price = pairBnbBlance / pariTokenBlance;

    setPriceTokenBnb(price);


    const _totalTokensLocked = await contract.totalTokenOwed();
    const _totalLpLocked = await contract.totalLPTokensOwed();

    // console.log(+formatUnits(_tvl.toString(), tokenDecimals.toString()), "tvl")
    setTotalTokensLocked(+formatUnits(_totalTokensLocked.toString(), tokenDecimals.toString()));
    // setTvl(+formatEther(_tvl.toString()));
    setTotalLpLocked(+formatEther(_totalLpLocked.toString()));
    const _apr = await contract.rewardRate();
    const lpAmount = await contract.totalLPTokensOwed();

    // console.log("apr", ((+formatUnits(_apr.toString(), tokenDecimals.toString()) * 365 * (60 * 60 * 24)) /
    //   +formatEther(lpAmount.toString())
    // ).toFixed(2))
    if (+lpAmount > 0) {
      setApr(((+formatUnits(_apr.toString(), tokenDecimals.toString()) * 365 * (60 * 60 * 24)) /
        +formatEther(lpAmount.toString())
      ).toFixed(2));
    } else {
      setApr((+formatUnits(_apr.toString(), tokenDecimals.toString()) * 365 * (60 * 60 * 24)).toFixed(2))
    }




    if (address && isConnected) {
      const _oldUser = await contract.userStakeAgain(address);
      setOldUser(_oldUser);

      const _referralBonus = await contract.referralIncome(address);
      // console.log("referralBonus", _referralBonus.toString());
      setComissionEarned(formatEther(_referralBonus.toString()).toString())
      const _accountTokenContractBalance = await contract.addressToUserInfo(
        address
      );

      setAccountTokenBalance(
        +formatUnits(_accountTokenContractBalance.tokenBalance.toString(), tokenDecimals.toString())
      );

      setAccountTokenContractBalance(
        +formatUnits(
          _accountTokenContractBalance.tokenBonds.toString(),
          tokenDecimals.toString()
        )
      );

      setBalance(+formatEther(_accountTokenContractBalance.bnbBalance.toString()));

      const _pendigRewards = await contract.earned(address);

      setPendingRewards(
        +formatUnits(_pendigRewards.toString(), tokenDecimals.toString())
      );

      const _paidRewards = await contract.userPaidRewards(address);

      setPaidRewards(
        +formatUnits(_paidRewards.toString(), tokenDecimals.toString())
      );
    }
  };

  useEffect(() => {
    if (contract) {
      init();
    }
  }, [address, isConnected, contract, chain?.id]);

  const { write: rewards, data: rewardsData } = useContractWrite({
    address: contractAddress,
    abi: abi,
    functionName: 'claimRewards',
    onError: (error) => {
      var str = JSON.stringify(error)
      var obj = JSON.parse(str)
      var reason = obj.cause.reason
      reason ? notifyError(`${reason}`) : notifyError(`${obj?.details}`)
    }
  })

  const rewardsTx = useWaitForTransaction({
    hash: rewardsData?.hash,
    onSuccess: () => {
      notifySuccess('Rewards Claimed Successfully')
      init();
    }
  })

  const { write: emergencyUnstake, data: emergencyData } = useContractWrite({
    address: contractAddress,
    abi: abi,
    functionName: 'emergencyUnstake',
    args: [emergencyUnstakeAmount.length > 0 ? String(+emergencyUnstakeAmount * 100) : "0"],
    onError: (error) => {
      var str = JSON.stringify(error)
      var obj = JSON.parse(str)
      var reason = obj.cause.reason
      reason ? notifyError(`${reason}`) : notifyError(`${obj?.details}`)
    }
  })

  const emergencyTx = useWaitForTransaction({
    hash: emergencyData?.hash,
    onSuccess: () => {
      notifySuccess('Tokens Emergency Unstake Successfully')
      init();
    }

  })

  const { write: unStake, data: unstakeData } = useContractWrite({
    address: contractAddress,
    abi: abi,
    functionName: 'redeemTokenBond',
    args: [unstakeAmount.length > 0 ? String(+unstakeAmount * 100) : "0"],
    onError: (error) => {
      var str = JSON.stringify(error)
      var obj = JSON.parse(str)
      var reason = obj.cause.reason
      reason ? notifyError(`${reason}`) : notifyError(`${obj?.details}`)
    }
  })

  const unstakeTx = useWaitForTransaction({
    hash: unstakeData?.hash,
    onSuccess: () => {
      notifySuccess('Tokens Unstake Successfully')
      init();
    }

  })

  const handleBuy = () => {
    setshowBuy(!showBuy);
  };

  const handleAlert = () => {
    setshowAlert(!showAlert);
  };

  const handleUnstakeAlert = () => {
    setShowUnstakeAlert(!showUnstakeAlert);
  }

  const Row = ({ text, value, price }) => (
    <div className="flex justify-between items-center">
      <p className="text-lyellow text-xl font-semibold w-[50%]">{text}:</p>
      <div className="bg-lightgreen px-8  py-1 rounded-xl flex flex-wrap w-56 justify-between items-center">
        <p className="text-black font-semibold text-base text-center">
          {value}
        </p>
        <p className="text-black text-base text-center">${price}</p>
      </div>
    </div>
  );

  return (
    <>
      <div className="w-full flex flex-col justify-between">
        <div className="flex justify-evenly flex-col md:flex-row mt-8">
          <div className="">
            <div className="space-y-4 text-white">
              <div className="flex flex-col items-center justify-between px-[5em] pb-5 pt-2">
                <div className="relative block px-6 py-4 bg-lyellow rounded-t-2xl border border-black/20 shadow-md w-[22em] sm:w-[30em] xl:w-[35em] ">
                  <div className="flex items-center">
                    <div className="flex flex-wrap justify-between items-center w-[100%]">
                      <div>
                        <p className="text-white text-xl font-semibold">
                          Earn {tokenName}
                        </p>
                        <p className="text-white text-sm italic">
                          stake {tokenName}-Bills
                        </p>
                      </div>
                      <div className="mt-2 sm:none">
                        <p className="text-white text-sm">
                          Start: {moment.unix(startDate).utc().format("lll")}
                        </p>
                        <p className="text-white text-sm">
                          End: {moment.unix(endDate).utc().format("lll")}
                        </p>
                        <p className="text-white text-sm">
                          Duration: {days} days
                        </p>
                        <p className="text-white text-sm">
                          Remaining:{" "}
                          {days > remiangDays ? parseInt(days - remiangDays) : 0} days
                        </p>
                      </div>
                      <img
                        className="w-16 h-16 rounded-full hidden sm:block "
                        src={logo}
                        alt={tokenName}
                      />
                    </div>
                    <img
                      className="w-16 h-16 rounded-full  sm:hidden"
                      src={logo}
                      alt={tokenName}
                    />
                  </div>
                  <div className="absolute right-0 top-0 bg-lightgreen py-[2px] px-2 rounded-sm">
                    <p className="text-white text-sm">New!</p>
                  </div>
                </div>

                <div className="block px-6 py-3 bg-lgreen w-[22em] sm:w-[30em] xl:w-[35em] ">
                  <div className="flex items-center justify-center">
                    <div className=" text-white">
                      <a
                        className="flex items-center justify-center"
                        href={website}
                        target="_blank"
                      >
                        <p className="text-white text-sm mr-[4px]">
                          {tokenName}
                        </p>
                        <AiOutlineChrome size={15} />
                      </a>
                    </div>
                    <p className="mx-2 text-sm text-white">/</p>
                    <div className=" text-white">
                      <a
                        className="flex items-center justify-center"
                        href={bills}
                        target="_blank"
                      >
                        <p className="text-white text-sm mr-[4px]">
                          {tokenName}-Bills
                        </p>
                        <AiOutlineFileDone size={15} />
                      </a>
                    </div>
                    <p className="mx-2 text-sm text-white">/</p>
                    <div className=" text-white">
                      <a
                        className="flex items-center justify-center"
                        href={token}
                        target="_blank"
                      >
                        <p className="text-white text-sm mr-[4px]">
                          {tokenName}
                        </p>
                        <AiOutlineFileDone size={15} />
                      </a>
                    </div>
                  </div>
                  <div className="flex flex-col justify-center space-y-5 mt-6">
                    <Row
                      text={`${nativeSymbol} Balance`}
                      value={data ? Number(data.formatted).toFixed(3) : "0"}
                      price={data ? (bnbRate * Number(data.formatted)).toFixed(2) : "0"}
                    />
                    <Row
                      text={`${tokenName} Balance`}
                      value={accountTokenBalance.toFixed(2)}
                      price={(
                        accountTokenBalance *
                        priceTokenBnb *
                        bnbRate
                      ).toFixed(2)}
                    />
                    <Row
                      text={`${tokenName}-Bills Staked`}
                      value={accountTokenContractBalance.toFixed(2)}
                      price={(
                        accountTokenContractBalance *
                        lpValueInBnb *
                        bnbRate
                      ).toFixed(2)}
                    />
                    <Row
                      text={"Pending Rewards"}
                      value={pendingRewards.toFixed(2)}
                      price={(pendingRewards * priceTokenBnb * bnbRate).toFixed(
                        2
                      )}
                    />

                    <Row
                      text={"Paid Rewards"}
                      value={paidRewards.toFixed(2)}
                      price={(paidRewards * priceTokenBnb * bnbRate).toFixed(2)}
                    />
                  </div>
                  {!showBuy && !showAlert && !showUnstakeAlert && (
                    <div className="flex items-center justify-center sm:justify-between flex-wrap px-5 mt-8">
                      <div className="m-2 ">
                        <Button
                          text={`Purchase ${tokenName}-Bills!`}
                          onClick={handleBuy}
                        />
                      </div>
                      <Button text={"Collect Rewards"} onClick={() => {
                        if (isConnected) {
                          if (chain.id === chainId) {
                            rewards()
                          } else {
                            notifyError(`Please Connect To ${chainId === 56 ? "BSC Mainnet" : chainId === 1 ? "ETH Mainnet" : "BSC Testnet"} Network`)
                          }
                        } else {
                          notifyError("Please Connect Wallet")
                        }
                      }} />
                    </div>
                  )}

                  <div className="space-y-4 pt-4">
                    <div className="bg-[#fffbe7] border rounded flex items-center p-1">
                      <AiFillExclamationCircle
                        size={35}
                        className="mr-1 text-[#f2b233] text-xl"
                      />
                      <p className="text-black font-semibold text-center text-xs">
                        Your staked tokens are locked until:{" "}
                        {moment.unix(endDate).utc().format("lll")}. If you
                        unstake before this date, you will be penalized{" "}
                        {earlyUnstakefee}% of your staked tokens.
                      </p>
                    </div>
                    {showUnstakeAlert && !showAlert && (
                      <div>
                        <div className="flex justify-center mb-3">
                          <p className="text-lyellow text-lg font-semibold w-[40%]">Percentage</p>
                          <input
                            type="number"
                            placeholder="Enter Percentage"
                            value={unstakeAmount}
                            onChange={(e) => setUnstakeAmount(e.target.value)}
                            className="text-black border-lightgreen border-2 rounded-xl px-4 text-sm w-[50%]"
                          />
                        </div>
                        <div className="my-4 flex justify-center items-center font-sans pt-2 pb-4 ">
                          <div className="flex flex-col justify-center items-center">
                            <span
                              onClick={async () => {
                                setUnstakeAmount(
                                  String(25)
                                );
                              }}
                              style={{ cursor: "pointer" }}
                              className="bg-[#172c73] rounded py-1 px-4 text-sm font-semibold"
                            >
                              25%
                            </span>
                          </div>

                          <div className="flex flex-col justify-center items-center ml-3">
                            <span
                              onClick={async () => {
                                setUnstakeAmount(
                                  String(50)
                                );
                              }}
                              style={{ cursor: "pointer" }}
                              className="bg-[#172c73] rounded py-1 px-4 text-sm font-semibold"
                            >
                              50%
                            </span>
                          </div>

                          <div className="flex flex-col justify-center items-center ml-3">
                            <span
                              onClick={async () => {
                                setUnstakeAmount(
                                  String(75)
                                );
                              }}
                              style={{ cursor: "pointer" }}
                              className="bg-[#172c73] rounded py-1 px-4 text-sm font-semibold"
                            >
                              75%
                            </span>
                          </div>

                          <div className="flex flex-col justify-center items-center ml-3">
                            <span
                              onClick={async () => {
                                setUnstakeAmount(
                                  String(100)
                                );
                              }}
                              style={{ cursor: "pointer" }}
                              className="bg-[#172c73] rounded py-1 px-4 text-sm font-semibold"
                            >
                              100%
                            </span>
                          </div>
                        </div>
                        <div className="flex justify-center">
                          <Button text="Unstake"
                            onClick={() => {
                              if (isConnected) {
                                if (chain.id === chainId) {
                                  if (+unstakeAmount > 100) {
                                    notifyError("Invalid Percentage")
                                    return;
                                  }
                                  unStake()
                                } else {
                                  notifyError(`Please Connect To ${chainId === 56 ? "BSC Mainnet" : chainId === 1 ? "ETH Mainnet" : "BSC Testnet"} Network`)
                                }
                              } else {
                                notifyError("Please Connect Wallet")
                              }
                            }}
                          />
                          <div className="ml-4">
                            <Button text="Close" onClick={handleUnstakeAlert} />
                          </div>
                        </div>
                      </div>
                    )}
                    {showAlert && !showUnstakeAlert && (
                      <div>
                        <div className="flex justify-center mb-3">
                          <p className="text-lyellow text-lg font-semibold w-[40%]">Percentage</p>
                          <input
                            type="number"
                            placeholder="Enter Percentage"
                            value={emergencyUnstakeAmount}
                            onChange={(e) => setEmergencyUnstakeAmount(e.target.value)}
                            className="text-black border-lightgreen border-2 rounded-xl px-4 text-sm w-[50%]"
                          />
                        </div>
                        <div className="my-4 flex justify-center items-center font-sans pt-2 pb-4 ">
                          <div className="flex flex-col justify-center items-center">
                            <span
                              onClick={async () => {
                                setEmergencyUnstakeAmount(
                                  String(25)
                                );
                              }}
                              style={{ cursor: "pointer" }}
                              className="bg-[#172c73] rounded py-1 px-4 text-sm font-semibold"
                            >
                              25%
                            </span>
                          </div>

                          <div className="flex flex-col justify-center items-center ml-3">
                            <span
                              onClick={async () => {
                                setEmergencyUnstakeAmount(
                                  String(50)
                                );
                              }}
                              style={{ cursor: "pointer" }}
                              className="bg-[#172c73] rounded py-1 px-4 text-sm font-semibold"
                            >
                              50%
                            </span>
                          </div>

                          <div className="flex flex-col justify-center items-center ml-3">
                            <span
                              onClick={async () => {
                                setEmergencyUnstakeAmount(
                                  String(75)
                                );
                              }}
                              style={{ cursor: "pointer" }}
                              className="bg-[#172c73] rounded py-1 px-4 text-sm font-semibold"
                            >
                              75%
                            </span>
                          </div>

                          <div className="flex flex-col justify-center items-center ml-3">
                            <span
                              onClick={async () => {
                                setEmergencyUnstakeAmount(
                                  String(100)
                                );
                              }}
                              style={{ cursor: "pointer" }}
                              className="bg-[#172c73] rounded py-1 px-4 text-sm font-semibold"
                            >
                              100%
                            </span>
                          </div>
                        </div>
                        <div className="flex justify-center">
                          <Button text="Unstake"
                            onClick={() => {
                              if (isConnected) {
                                if (chain.id === chainId) {
                                  if (+emergencyUnstakeAmount > 100) {
                                    notifyError("Invalid Percentage")
                                    return;
                                  }
                                  emergencyUnstake()
                                } else {
                                  notifyError(`Please Connect To ${chainId === 56 ? "BSC Mainnet" : chainId === 1 ? "ETH Mainnet" : "BSC Testnet"} Network`)
                                }
                              } else {
                                notifyError("Please Connect Wallet")
                              }
                            }}
                          />
                          <div className="ml-4">
                            <Button text="Close" onClick={handleAlert} />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {showBuy && (
                  <Buy
                    handleBuy={handleBuy}
                    oldUser={oldUser}
                    init={init}
                    contractAddress={contractAddress}
                    refAddress={referralAddress}
                    chainId={chainId}
                  />
                )}
                {bonus && (
                  <div className="bg-white w-[22em] sm:w-[30em] xl:w-[35em] flex items-center p-2  ">
                    <FcInfo size={15} className="mr-1" />
                    <Marquee>
                      <p className="text-black font-semibold text-xs">
                        {tokenName}-Bills Bounus is live! Get {bonusPercentage}%
                        more {tokenName}
                        -Bills per BNB!
                      </p>
                    </Marquee>
                  </div>
                )}
                {address && <div className="h-[2px] bg-white w-[22em] sm:w-[30em] xl:w-[35em]" />}
                {address && <div className="flex justify-center w-full">
                  <div className="bg-[#4CA5D5] py-2 px-5 lg:py-0 w-[22em] sm:w-[30em] xl:w-[35em]">
                    <div className="flex flex-col justify-center items-center w-full pb-5">
                      <img
                        src={logo1}
                        alt="logo"
                        className="w-12 h-12 lg:w-16 md:h-16"
                      />

                      <h2 className="text-xs tracking-widest uppercase">
                        Your Referral Link
                      </h2>

                      <div className="bg-[#1F1F1F] p-1 md:px-4 mt-4 rounded flex items-center justify-center ">
                        <span className="text-[8px] xs:text-[9px] xl:text-[10px] text-gray-100 tracking-wider p-2 flex-grow text-center">
                          {`https://${window.location.hostname}/farms/${address}`}
                        </span>
                        <CopyToClipboard
                          onCopy={(e) => toast.success("Copied to clipboard")}
                          text={`https://${window.location.hostname}/farms/${address}`}
                        >
                          <IoIosCopy className="text-xl ml-2 cursor-pointer" />
                        </CopyToClipboard>
                      </div>

                      <span className="text-xs tracking-widest uppercase mt-5">
                        COMISSIONED EARNED
                      </span>

                      <div className="bg-[#1F1F1F] py-1 px-10 mt-2 rounded flex items-center justify-center">
                        <span className="text-xs text-gray-100 tracking-wider">
                          {Number(comissionEarned).toFixed(4)}
                        </span>
                      </div>

                      <span className="text-xs tracking-widest uppercase mt-5">
                        REFERRAL BONUS
                      </span>

                      <div className="bg-[#1F1F1F] py-1 px-10 mt-2 rounded flex items-center justify-center">
                        <span className="text-xs text-gray-100 tracking-wider">
                          {referralBonusPercentage}%
                        </span>
                      </div>
                    </div>
                  </div>
                </div>}
                <div className="bg-lgreen w-[22em] sm:w-[30em] xl:w-[35em] border border-black"></div>
                <div className="block p-6 bg-lgreen rounded-b-2xl mb-10 w-[22em] sm:w-[30em] xl:w-[35em] ">
                  <div className="flex flex-wrap justify-center px-6 items-center space-x-3 ">
                    <div className="flex flex-col items-center justify-center sm:mr-0 my-2">
                      <p className="text-black text-base font-bold">APR</p>
                      <div className="bg-lightgreen px-3 py-1 rounded-xl  text-center">
                        <p className="text-black font-semibold text-base">
                          {apr}%
                          {/* 250% */}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col items-center ml-8 sm:ml-0  my-2">
                      <p className="text-black text-base font-bold">TVL</p>
                      <div className="bg-lightgreen px-3 py-1 rounded-xl  text-center">
                        <p className="text-black font-semibold text-base">
                          {((+totalTokensLocked * priceTokenBnb * bnbRate) + (+totalLpLocked * lpValueInBnb * bnbRate)).toFixed(2)}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col items-center justify-center mt-6 sm:mt-0 my-2">
                      <div className="mb-2">
                        <Button
                          text={"Unstake"}
                          onClick={handleUnstakeAlert}
                          style={
                            +endDate > moment().unix()
                              ? `bg-[#dedede] shadow`
                              : ""
                          }
                          disabled={+endDate > moment().unix()}
                        />
                      </div>
                      <Button
                        text={"Emergency Unstake"}
                        onClick={handleAlert}
                        style={
                          +endDate < moment().unix()
                            ? `bg-[#dedede] shadow`
                            : ""
                        }
                        disabled={+endDate < moment().unix()}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Safo;
