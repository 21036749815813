import React from "react";

import { ConnectButton } from "@rainbow-me/rainbowkit";
import { Link } from "react-router-dom";
import { safoLogo } from "../../images";
import SideBar from "../BurgerMenu/SideBar";

const Header = ({ params }) => {  

  return (
    <nav className=" w-full">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto px-4 mb-2">
        <a href="https://safeonechain.com/" target="_blank">
          <img
            src={safoLogo}
            alt="safoonechain"
            className="w-30 h-20 cursor-pointer rounded-xl"
          />
        </a>
        <div className="flex md:order-2 mr-12 md:mr-0">


          <div>
            <ConnectButton />
          </div>


          <div className="md:hidden ml-auto">
            <SideBar
              params={params}
              pageWrapId={"page-wrap"}
              outerContainerId={"outer-container"}
            />
          </div>
        </div>
        <div
          className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1"
          id="navbar-sticky"
        >
          <ul className="flex flex-col p-4 md:p-0 mt-4 font-medium md:flex-row md:space-x-4 lg:space-x-8 md:mt-0 ">
            <li>
              <Link
                to="/"
                className="flex items-center cursor-pointer text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-lyellow md:p-0 md:dark:hover:text-lyellow dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
              >

                Overview
              </Link>
            </li>
            <li>
              <Link
                to="/stakes"
                className="flex items-center cursor-pointer text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-lyellow md:p-0 md:dark:hover:text-lyellow dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
              >

                Staking

              </Link>
            </li>
            <li>
              <a
                target="_blank"
                className="flex items-center cursor-pointer  text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-lyellow md:p-0 md:dark:hover:text-lyellow dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
              >

                Dashboard
              </a>
            </li>
            <li>
              <Link
                state={params}
                to={"/farms"}
                // href="#"

                className="flex items-center text-white  cursor-pointerrounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-lyellow md:p-0 md:dark:hover:text-lyellow dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
              >

                OTC Yield Farm
              </Link>
            </li>
            <li>
              <a
                // href="#"

                className="flex items-center text-white rounded cursor-pointer hover:bg-gray-100 md:hover:bg-transparent md:hover:text-lyellow md:p-0 md:dark:hover:text-lyellow dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
              >

                ISPO
              </a>
            </li>
            <li>
              <a
                href={"https://launchpad.safeonechain.com/"}
                target="_blank"
                // state={params}
                // href="#"

                className="flex items-center text-white  cursor-pointerrounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-lyellow md:p-0 md:dark:hover:text-lyellow dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
              >

                SAFO Launchpad
              </a>
            </li>

          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
