import React from "react";
import { Link } from "react-router-dom";

const Button2 = ({ text, onClick, style,params, disabled, to = "" }) => {
  return (
    <Link to={to} state={params}>
      <div className="flex justify-start items-center">
        <button
          className={`bg-lyellow text-sm font-semibold py-2 px-4 text-whit rounded-lg ${style}`}
          onClick={onClick}
          disabled={disabled}
        >
          {text}
        </button>
      </div>
    </Link>
  );
};

export default Button2;
