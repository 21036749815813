import React from "react";
import Button from "../BugBounty/Button";
import Button2 from "../BugBounty/Button2";

const Card = ({ logo, title, text, btnText,params }) => {
  return (
    <div className="rounded flex flex-col justify-between p-6 shadow-sm bg-[#15406a]/20 m-4 w-[18rem] h-[22rem]">
      <div className="mb-20 flex flex-col items-center ">
        <img src={logo} alt="safo" className="w-16 h-16 mb-4" />
        <p className="text-white font-semibold">{title}</p>
        <div class="w-[100%] bg-gradient-to-r from-gray-500 from-10% via-white via-30% to-gray-500 to-90%  h-1 my-3"></div>
        <p className="font-semibold text-white text-center">{text}</p>
      </div>

      {title === "OTC Yield Farm" ? (
        <Button2
          to="/farms"
          params={params}
          text={btnText}
          style={"ml-auto mr-auto text-white bg-[#241045] w-[100%]"}
        />
      ):title === "Stake" ? (
        <Button2
          to="/stakes"
          params={params}
          text={btnText}
          style={"ml-auto mr-auto text-white bg-[#241045] w-[100%]"}
        />
      )  :title === "Stake V2" ? (
        <Button2
          to="/stakes2"
          text={btnText}
          params={params}
          style={"ml-auto mr-auto text-white bg-[#241045] w-[100%]"}
        />
      )  : (
        <Button
          text={btnText}
          style={"ml-auto mr-auto text-white bg-[#241045] w-[100%]"}
        />
      )}
    </div>
  );
};

export default Card;
