import React, { useState } from "react";
import OldCard from "./OldCard";
import { QbeAbi, QbeAddress } from "../../constants/Old/qbe";
import { SafoAddress, safoAbi } from "../../constants/Old/safo";
import { KuzcoAddress, kuzcoAbi } from "../../constants/Old/kuzco";
import { SafoQbeAbi, SafoQbeAddress } from "../../constants/Old/safoQbe";
import { SafoSafoAbi, SafoSafoAddress } from "../../constants/Old/safosafo";
import { safoKuzcoAbi, safoKuzcoAddress } from "../../constants/Old/safoKuzco";
import { oldSafosafoAbi, oldSafosafoAddress } from "../../constants/Old/oldSafosafo";
import { oldSafoQbeAbi, oldSafoQbeAddress } from "../../constants/Old/oldSafoQbe";
import { qbeqbeAbi, qbeqbeAddress } from "../../constants/Old/qbeqbe";
import { kuzcoBnbAbi, kuzcoBnbAddress } from "../../constants/Old/kuzcoBnb";
import KuzcoCard from "./KuzcoCard";
import SafoQbe from "./SafoQbe";
import SafoSafo from "./SafoSafo";
import SafoKuzco from "./SafoKuzco";
import OldSafoSafo from "./OldSafoSafo";
import OldSafoQbe from "./OldSafoQbe";
import QbeQbe from "./QbeQbe";
import Kuzcobnb from "./KuzcoBnb";
import { kuzcoInuLogo, qbeLogo, safoLogo } from "../../images";

const Old = () => {
  const [bugBounties, setbugBounties] = useState([]);

  // const host = "https://admin-d.herokuapp.com

  return (
    <>
      <div className=" flex flex-col justify-center items-center md:grid md:grid-cols-1 lg:grid-cols-2 ">
        {bugBounties.length > 0 ? (
          bugBounties.map((bugbounty) => (
            <OldCard key={bugbounty._id} {...bugbounty} />
          ))
        ) : (
          <>
            {/* <OldCard
              tokenName="QBE"
              contractAddress={QbeAddress}
              abi={QbeAbi}
              logo={qbeLogo}
              website={"https://qbe-ecosystem.com"}
              bills={"https://bscscan.com/address/0xb36991dECd53cAD2D3B36E66deC1028D0660b3E8"}
              token={"https://bscscan.com/token/0xe13e2b3e521080e539260d1087c087582d1bc501"}
            />
            <OldCard
              tokenName="SAFO"
              contractAddress={SafoAddress}
              abi={safoAbi}
              logo={safoLogo}
              website={"https://safeonechain.com"}
              bills={"https://bscscan.com/address/0xda27beea1950574112bad6928ad687cb9082401e"}
              token={"https://bscscan.com/token/0xd57Cfb45347573f4F2471fA1572Eb79d0Ba29Fd0"} 
            />
            <KuzcoCard
              tokenName="KUZCO"
              contractAddress={KuzcoAddress}
              abi={kuzcoAbi}
              logo={kuzcoInuLogo}
              website={"https://kuzcoinu.com/"}
              bills={"https://bscscan.com/address/0x3a213feb0bc2d47b3b4a17370f5084185b21ca62"}
              token={"https://bscscan.com/token/0x7df332d3183db400b69b71d4b6bcd0354293cdd9"}
            /> */}

            {/* <SafoQbe
              tokenName="SAFO-QBE"
              contractAddress={SafoQbeAddress}
              abi={SafoQbeAbi}
              logo={safoLogo}
              website={"https://safeonechain.com"}
              bills={"https://bscscan.com/address/0xe29681658be65148d38a0e048247f6dba113215a"}
              token={"https://bscscan.com/token/0xd57Cfb45347573f4F2471fA1572Eb79d0Ba29Fd0"}
            />
            <SafoSafo
              tokenName="SAFO-SAFO"
              contractAddress={SafoSafoAddress}
              abi={SafoSafoAbi}
              logo={safoLogo}
              website={"https://safeonechain.com"}
              bills={"https://bscscan.com/address/0x07cf125ce9cf89611f3852f3d66f16882412f339"}
              token={"https://bscscan.com/token/0xd57Cfb45347573f4F2471fA1572Eb79d0Ba29Fd0"}
            />
            <SafoKuzco
              tokenName="SAFO-KUZCO"
              contractAddress={safoKuzcoAddress}
              abi={safoKuzcoAbi}
              logo={safoLogo}
              website={"https://safeonechain.com"}
              bills={"https://bscscan.com/address/0x4d8a5eb9687b8b6f48b4456cc4c3ee3d57ad7afc"}
              token={"https://bscscan.com/token/0xd57Cfb45347573f4F2471fA1572Eb79d0Ba29Fd0"}
             
            /> */}
            {/* <OldSafoSafo
              tokenName="OLD-SAFO-SAFO"
              contractAddress={oldSafosafoAddress}
              abi={oldSafosafoAbi}
              logo={safoLogo}
              website={"https://safeonechain.com"}
              bills={"https://bscscan.com/address/0xea4a5cd0abd3a9cb8f7d9ec6bac43e1678aea962"}
              token={"https://bscscan.com/token/0xd57Cfb45347573f4F2471fA1572Eb79d0Ba29Fd0"}
             
            />
            <OldSafoQbe
              tokenName="OLD-SAFO-QBE"
              contractAddress={oldSafoQbeAddress}
              abi={oldSafoQbeAbi}
              logo={safoLogo}
              website={"https://safeonechain.com"}
              bills={"https://bscscan.com/address/0x28486cbd4196068a53639014862789809f15e863"}
              token={"https://bscscan.com/token/0xd57Cfb45347573f4F2471fA1572Eb79d0Ba29Fd0"}
             
            /> */}
            {/* <QbeQbe
              tokenName="QBE_QBE"
              contractAddress={qbeqbeAddress}
              abi={qbeqbeAbi}
              logo={"/qbe.jpg"}
              website={"https://qbe-ecosystem.com"}
              bills={"https://bscscan.com/address/0x4e27fe7a2be6f600bb1df7718e8a784262ca91e"}
              token={"https://bscscan.com/token/0xe13e2b3e521080e539260d1087c087582d1bc501"} 
            /> */}
            {/* <Kuzcobnb
              tokenName="KUZCO-BNB"
              contractAddress={kuzcoBnbAddress}
              abi={kuzcoBnbAbi}
              logo={"/kuzcoInu.jpg"}
              website={"https://kuzcoinu.com/"}
              bills={"https://bscscan.com/address/0x2420331f5c0b78c3c58545030a8ead086dbe7b4d"}
              token={"https://bscscan.com/token/0x7df332d3183db400b69b71d4b6bcd0354293cdd9"}
            /> */}
          </>
        )}
      </div>
      <div className="flex justify-center items-center">
        <div className="w-11/12 h-[0.2px] border-t border-lyellow" />
      </div>
    </>
  );
};

export default Old;
