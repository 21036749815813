import {
  RainbowKitProvider,
  darkTheme,
  getDefaultWallets
} from '@rainbow-me/rainbowkit';
import '@rainbow-me/rainbowkit/styles.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { MoralisProvider } from 'react-moralis';
import { Provider } from 'react-redux';
import { WagmiConfig, configureChains, createConfig } from 'wagmi';
import {
  bsc,
  bscTestnet,
  mainnet
} from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';
import App from './App';
import './index.css';
import { store } from './redux/store';

const { chains, publicClient } = configureChains(
  [mainnet, bsc, bscTestnet],
  [
    publicProvider()
  ]
);

const { connectors } = getDefaultWallets({
  appName: 'Safeone Farms',
  projectId: 'dee3654b147279c6d6f48b78b1c1cc37',
  chains
});

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider chains={chains} theme={darkTheme({
        accentColor: '#172c73',
        accentColorForeground: 'white',
        borderRadius: 'large',
        fontStack: 'system',
        overlayBlur: 'small',
      })}>
        <Provider store={store}>
          <MoralisProvider initializeOnMount={false}>
            <App />
          </MoralisProvider>
        </Provider>
      </RainbowKitProvider>
    </WagmiConfig>
  </React.StrictMode>
);
