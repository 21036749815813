import Home from "./pages/Home";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Error from "./pages/Error";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import Farms from "./pages/Farms";
import Stake from "./pages/Stake";
import Stake2 from "./pages/Stake2";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/:params" element={<Home />} />
          <Route path="/stakes" element={<Stake />} />
          <Route path="/stakes2" element={<Stake2 />} />
          <Route path="/farms" element={<Farms />} />
          <Route path="/farms/:referralAddress" element={<Farms />} />
          <Route path="*" element={<Error />} />
        </Routes>
      </Router>
      <ToastContainer />
    </>
  );
}

export default App;
