import React from "react";
import Header from "../components/Header/Header";
import FAQs from "../components/FAQs";
import Footer from "../components/Footer/Footer";
import BugBounty from "../components/BugBounty/BugBounty";
import Banners from "../components/BugBounty/Banners";
import { useSelector } from "react-redux";
import Card from "../components/Cards";
import Button from "../components/BugBounty/Button";
import { useParams } from "react-router-dom";

const Home = () => {
  const { connect } = useSelector((state) => state.provider);
  const { params } = useParams();

  return (
    <>
      {/* {connect ? (
        <div className="overflow-y-hidden main">
          <div className="bg-black/25">
            <Header />
            <BugBounty />

            <FAQs />

            <Footer />
          </div>
        </div>
      ) : ( */}
        <div className=" ">
          <Header                 params={params} />
          <div className="flex flex-wrap items-center justify-center">
            <div className="px-4 flex flex-wrap  justify-center items-center">
              <Card
                params={params}
                logo={"/assets/stake.png"}
                title={"Stake"}
                text={"Stake tokens and receive rewards"}
                btnText={"Pools"}
              />
                {/* <Card
                logo={"/assets/stake.png"}
                title={"Stake V2"}
                text={"Stake tokens and receive rewards"}
                btnText={"Pools V2"}
              /> */}
              <Card
                              params={params}
                logo={"/assets/rewards.png"}
                title={"OTC Yield Farm"}
                text={"Claim your rewards in SAFO"}
                btnText={"OTC Yield Farm"}
              />
              <Card
                              params={params}
                logo={"/assets/vesting.png"}
                title={"ISPO"}
                text={"Your vesting rewards and deposits"}
                btnText={"ISPO"}
              />
            </div>
            <div>
              {" "}
              <div className="flex items-center rounded shadow-xl bg-[#15406a]/20 pl-4 pr-44 my-2 py-4">
                <img src="/assets/price.png" alt="safo" className="w-16 h-16" />
                <div>
                  <p className="text-gray-400 font-semibold">SAFO Price</p>
                  <p className="text-white font-bold"> $0.0001569</p>
                </div>
              </div>
              <div className="flex items-center rounded shadow-xl bg-[#15406a]/20 pl-4 pr-44 my-2 py-4">
                <img src="/assets/marketcap.png" alt="safo" className="w-16 h-16" />
                <div>
                  <p className="text-gray-400 font-semibold">Market Cap</p>
                  <p className="text-white font-bold"> $46,795</p>
                </div>
              </div>
              <div className=" rounded shadow-xl bg-[#15406a]/20 pl-4 my-2">
                <div className="flex items-center ">
                  <img src="/assets/currency.png" alt="safo" className="w-16 h-16" />
                  <div>
                    <p className="text-gray-400 font-semibold">
                      Estimated Holders
                    </p>
                  </div>
                </div> 
                <Button text="Coming Soon" style={"w-[20rem] text-white mb-8 mt-4"} />

              </div>
              <div className=" rounded shadow-xl bg-[#15406a]/20 pl-4 my-2">
                <div className="flex items-center ">
                  <img src="/assets/staked.png" alt="safo" className="w-16 h-16" />
                  <div>
                    <p className="text-gray-400 font-semibold">
                     Total Amount Staked
                    </p>
                  </div>
                </div> 
                <Button text="Coming Soon" style={"w-[20rem] text-white mb-8 mt-4"} />

              </div>
            </div>
          </div>
          <Footer style={"bg-[#241045] mt-4"}/>
        </div>
      {/* )} */}
    </>
  );
};

export default Home;
