import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  connect: false,
  accountAddress: "",
};

export const providerSlice = createSlice({
  name: "provider",
  initialState,
  reducers: {
    connect: (state, action) => {
      // console.log('connect', action.payload);å
      return {
        connect: action.payload.connect,
        provider: action.payload.provider,
        signer: action.payload.signer,
        accountAddress: action.payload.address,
        chainId : action.payload.chainId
      };
    },
    disconnect: (state) => {
      return {
        connect: false,
        accountAddress: "",
      };
    },
    accountChange: (state, action) => {
      return { ...state, accountAddress: action.payload };
    },
    toggleConnect: (state) => {
      return { ...state, connect: !state.connect };
    },
    setAddress: (state, action) => {
      return {
        ...state,
        accountAddress: action.payload.address,
        provider: action.payload.provider,
      };
    },
    disconnectWallet: (state) => {
      return {
        connect: false,
        accountAddress: "",
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  connect,
  disconnect,
  accountChange,
  toggleConnect,
  setAddress,
  disconnectWallet,
} = providerSlice.actions;

export default providerSlice.reducer;
